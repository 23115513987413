// Styles
import Styles from "./styles.module.scss";

const Card = (props) => {
  const item = props.item;

  return (
    <div className={Styles.card_wrapper}>
      <h1>{item.title}</h1>
      <p>{item.descr}</p>
    </div>
  );
};

const values = [
  {
    id: 1,
    title: "Accessible Innovation",
    descr: `At Gart Tech, we are committed to developing new, powerful technologies that prioritize accessibility for everyone. Our goal is to bridge the gap between complexity and ease of use, ensuring that our advancements are readily available and applicable for individuals and businesses alike.`,
  },
  {
    id: 2,
    title: "Proactive Technology",
    descr: `We believe in technology that can manage 
    itself as much as possible. We designed Aki7 
    to proactively troubleshoot, solve challenges 
    and find solutions through conversation with 
    a human colleague.`,
  },
  {
    id: 3,
    title: "Human-Centric System",
    descr: `Gart Tech is dedicated to transforming information systems into intuitive, human-like solution. Breaking away from the rigidity of traditional systems, we create flexible information systems that resonate with the needs and realities of users.`,
  },
];

const CoreValues = () => {
  return (
    <section className={Styles.wrapper}>
      <span className={Styles.shadow_title} />

      <div className={Styles.header_wrapper}>
        <span className={Styles.header_icon} />

        <h1 className={Styles.title}>{"Core values"}</h1>
      </div>

      <div className={Styles.values}>
        {values.map((item, index) => {
          return <Card key={item.id} item={item} index={index} />;
        })}
      </div>
    </section>
  );
};

export default CoreValues;
