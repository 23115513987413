// Styles
import Styles from "./styles.module.scss";
// Images
import logo from "../../theme/assets/icons/logo.svg";
// Components
import Nav from "../Nav";
import SideNav from "../SideNav";
import { NavLink } from "react-router-dom";

const Header = (props) => {
  const { sideNavIsOpen, setSideNavIsOpen } = props;
  return (
    <header className={Styles.container}>
      <div
        className={`${Styles.header} ${
          props.headerBorder ? Styles.header_border : null
        }`}
      >
        <div className={Styles.header_logo}>
          <NavLink to="/">
            <img src={logo} alt={"logo image"} />
          </NavLink>
        </div>
        <div className={Styles.header_interaction}>
          <Nav />
        </div>

        <SideNav
          sideNavIsOpen={sideNavIsOpen}
          setSideNavIsOpen={setSideNavIsOpen}
        />
      </div>
    </header>
  );
};

export default Header;
