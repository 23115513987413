import Main from './Main';
import BasicInfo from './BasicInfo';
import Donation from './Donation';
import AboutUs from './AboutUs';
import AreasExpertise from './AreasExpertise';
import Cases from './Cases';
import GetInTouch from './GetInTouch';
import TheyTrustUs from './TheyTrustUs';
import HowWeWork from './HowWeWork';
import OurStory from './OurStory';
import CoreValues from './CoreValues';
import Technologies from './Technologies';
import MediasFeedback from './MediasFeedback';
import Project from './Project';
import WhatWeDo from './WhatWeDo';
import ClientsFeedback from './ClientsFeedback';
import Phases from './Phases';
import Portfolio from './Portfolio';
import JoinUs from './JoinUs';
import LinkTo from './LinkTo';

export {
    Main,
    BasicInfo,
    Donation,
    AboutUs,
    AreasExpertise,
    Cases,
    GetInTouch,
    TheyTrustUs,
    HowWeWork,
    OurStory,
    CoreValues,
    Technologies,
    MediasFeedback,
    Project,
    WhatWeDo,
    ClientsFeedback,
    Phases,
    Portfolio,
    JoinUs,
    LinkTo
};
