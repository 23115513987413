import React from "react";
import Styles from "./styles.module.scss";

// Core
import { useState } from "react";
// Instruments
import emailjs from "emailjs-com";
// Mui
import { Button, Alert, Snackbar } from "@mui/material";

// Components
import Spinner from "../Spinner";

const CloseSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M18 6.25L6 18.25"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 6.25L18 18.25"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const CareerModal = ({ onClose, isModalOpen }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [answer, setAnswer] = useState({});
  const [docs, setDocs] = useState([]);
  const specialities = [
    "Advanced Tech",
    "Big Data",
    "Business Analysis",
    "Business Operations",
    "Data Science",
    "Delivery Leadership",
    "Finance",
    "Global Talent Operations",
    "Human Resources",
    "Information Security",
    "IT",
    "Learning & Certification",
    "Management",
    "Marketing",
    "Platforms",
    "Product Management",
    "Project Management",
    "Sales",
    "Software Development",
    "Software Testing",
    "System Architecture",
    "System Integration",
    "Technical Communication",
    "Technology Consulting",
    "UI/UX",
  ];

  const onFileChange = (event) => {
    setAnswer("");
    const inputFile = document.getElementById("files").files;
    // console.log("inputFile", inputFile);
    // const files = [...inputFile];

    if (docs.length >= 5) {
      setAnswer({ status: 500, text: "Only 5 files are allowed to upload." });
      setOpen(true);
      return;
    }

    const fileSizeSum = docs.reduce((previousValue, currentValue) => {
      return previousValue + currentValue.size;
    }, 0);
    if (fileSizeSum > 20000000) {
      setAnswer({ status: 500, text: "Maximum files size 20mb" });
      setOpen(true);
      return;
    }

    setDocs((prevFiles) => [...prevFiles, ...inputFile]);
  };

  const deleteAllFiles = () => {
    document.getElementById("files").value = null;
    setDocs([]);
  };

  const alertSettings = {
    vertical: "top",
    horizontal: "center",
  };

  const sendEmail = async (formData) => {
    const email = formData.get("email");
    const specialization = formData.get("specialization");
    const experience = formData.get("experience");
    const linkedIn = formData.get("linkedIn");
    const github = formData.get("github");
    const filesInput = formData.getAll("files");

    const data = new FormData();

    // Append fields to FormData object
    data.append("email", email);
    data.append("specialization", specialization);
    data.append("experience", experience);
    data.append("linkedIn", linkedIn);
    data.append("github", github);

    // Append each file to the FormData object
    docs.forEach((file) => {
      data.append("files", file);
    });
    setLoading(true);

    try {
      const response = await fetch("https://gart.technology/api/email", {
        method: "POST",
        body: data,
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData.message);
        setAnswer({
          status: response.status,
          text: "Message Sent, We will get back to you shortly",
        });
        setOpen(true);
        document.getElementById("files").value = null;
        setDocs([]);
        setLoading(false);
      } else {
        console.error("Failed to send email:", response.statusText);
      }
    } catch (error) {
      console.error("Error sending email:", error);
      setAnswer({
        status: error.status,
        text: "An error occurred, Please try again",
      });
      setOpen(true);
      document.getElementById("files").value = null;
      setDocs([]);
      setLoading(false);
    }
  };

  const submitForm = (event) => {
    console.log("submited", event.target);
    const formData = new FormData(event.target);

    event.preventDefault();
    sendEmail(formData);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div
      style={{
        display: isModalOpen ? "flex" : "none",
      }}
      className={Styles.modal}
    >
      <section id={"contact"} className={Styles.container}>
        {/* <span className={Styles.bg_image} /> */}
        <div onClick={onClose} className={Styles.close} />

        <div className={Styles.wrapper}>
          <div className={Styles.title_wrapper}>
            <span className={Styles.shadow_title}>Apply now</span>
            <h1 className={Styles.title}>Apply now</h1>
          </div>
          <h2 className={Styles.info_message}>
            We always need talented people on board! Fill in this application to
            join our team.
          </h2>

          <form onSubmit={submitForm} className={Styles.form}>
            <div className={Styles.left_column}>
              <div className={Styles.form_item}>
                <p className={Styles.label}>{"Email"}</p>
                <input
                  name={"email"}
                  type="email"
                  placeholder={"Your email"}
                  pattern="[a-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  disabled={loading}
                  required
                  className={Styles.form_field}
                />
              </div>

              <div className={Styles.form_item}>
                <p className={Styles.label}>{"Specialization"}</p>
                <select
                  name="specialization"
                  id="specialization"
                  disabled={loading}
                  className={Styles.custom_select}
                >
                  {specialities.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className={Styles.user_warning}>
                {"Please, attach one or all the necessary files."}
              </div>

              <div className={Styles.files_wrapper}>
                <p className={Styles.label}>{"Attach files"}</p>
                <div className={Styles.content_wrapper}>
                  <div className={Styles.files_list}>
                    {docs.map((file, index) => {
                      return (
                        <div key={index}>
                          <p>{file.name}</p>
                        </div>
                      );
                    })}
                  </div>
                  {!docs.length && (
                    <span className={Styles.input_placeholder}>
                      {"(CV, portfolio, cover letter)"}
                    </span>
                  )}
                  <div className={Styles.buttons}>
                    {!!docs.length && (
                      <button
                        className={Styles.delete_btn}
                        onClick={deleteAllFiles}
                      >
                        {"Delete All"}
                      </button>
                    )}
                    <div className={Styles.gradient_bg}>
                      <input
                        type="file"
                        multiple
                        name={"files"}
                        id="files"
                        onChange={onFileChange}
                        className={Styles.file_input}
                        disabled={loading}
                      />

                      <label
                        className={Styles.file_input_label}
                        htmlFor="files"
                      >
                        <span>Select a File</span>
                      </label>
                      {/* <div
                        style={{
                          position: "relative",
                        }}
                      >
                        <input
                          type="file"
                          multiple
                          name={"files"}
                          id="files"
                          onChange={onFileChange}
                          className={Styles.career_apply_small}
                          disabled={loading}
                        />

                        <span className={Styles.apply_text}>
                          {loading ? <Spinner /> : "Send"}
                        </span>
                        <div className={Styles.apply_blur} />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={Styles.right_column}>
              <div className={Styles.form_item}>
                <p className={Styles.label}>{"Experience"}</p>
                <select
                  name="experience"
                  id="experience"
                  disabled={loading}
                  className={Styles.custom_select}
                >
                  <option value="less than 1 year">less than 1 year</option>
                  <option value="2 years">2 years</option>
                  <option value="3 years">3 years</option>
                  <option value="4 years">4 years</option>
                  <option value="5+ years">5+ years</option>
                </select>
              </div>

              <div className={Styles.form_item}>
                <p className={Styles.label}>{"LinkedIn"}</p>
                <input
                  name={"linkedIn"}
                  placeholder="https:www.linkedin.com/in/john-johnson/"
                  pattern="^(http(s)?:\/\/)?(www\.)?linkedin.com\/.+"
                  disabled={loading}
                  className={Styles.form_field}
                />
              </div>

              <div className={Styles.form_item}>
                <p className={Styles.label}>{"Github"}</p>
                <input
                  name={"github"}
                  placeholder={"https:github.com/john-johnson"}
                  pattern="^(http(s?):\/\/)?(www\.)?github.com\/.+"
                  disabled={loading}
                  className={Styles.form_field}
                />
              </div>
              <button
                type={"submit"}
                disabled={loading}
                className={Styles.career_apply}
              >
                <span className={Styles.apply_text}>
                  {loading ? <Spinner /> : "Send"}
                </span>
              </button>
            </div>
          </form>
          <Snackbar
            anchorOrigin={alertSettings}
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity={answer.status === 200 ? "success" : "error"}
              variant={"filled"}
              sx={{ width: "100%" }}
            >
              {answer.text}
            </Alert>
          </Snackbar>
        </div>
      </section>
    </div>
  );
};

export default CareerModal;
