// Styles
import Styles from "./styles.module.scss";

const OurStory = () => {
  return (
    <section className={Styles.container}>
      <div className={Styles.wrapper}>
        <span className={Styles.shadow_title} />
        <h2 className={Styles.title}>About us</h2>
        <div className={Styles.mob_wrapper}>
          <div className={Styles.logo} />
        </div>

        <div className={Styles.descr}>
          <div className={Styles.text_content}>
            <div className={Styles.story_title}>
              <span className={Styles.shadow_title} />

              <div className={Styles.story_header_wrapper}>
                <span className={Styles.header_icon} />
                <h2>Our story</h2>
              </div>
            </div>

            <div className={Styles.text_wrapper}>
              <div>
                <p>
                  In 2022, when the full-scale war in Ukraine started, we came
                  together as a diverse group of talented individuals in a tech
                  volunteer movement.
                </p>

                <p>
                  Our journey began with volunteer projects aimed at helping
                  victims of the conflict , but as time progressed, we saw a
                  burning need to bring in work to Ukraine - a kind of work
                  fitting for the bright tech engineers we have gathered around.
                  We put our focus on cutting-edge technologies that we believe
                  can fundamentally change the way we work.
                </p>
              </div>

              <div>
                <p>
                  That is why, as Gart Tech, we chose Large Language Model (LLM)
                  systems as our core focus.
                </p>

                <p>
                  At the heart of our expertise lies the construction of the
                  Aki7 solution. Built on various large language model systems,
                  Aki7 operates in a complex, chaotic, and fast-changing
                  business environment. It can effectively cooperate with real
                  people, and learn and evolve alongside them.
                </p>
              </div>
            </div>
          </div>

          <div className={Styles.mission_title}>
            <span className={Styles.shadow_title} />

            <div className={Styles.mission_header_wrapper}>
              <span className={Styles.header_icon} />
              <h2>Mission</h2>
            </div>
          </div>

          <div className={Styles.mission_text}>
            <p>
              Gart Tech is on a mission to provide a tool that empowers
              individuals to effortlessly translate their vision into reality.
              Aki7, our flagship solution, proactively communicates and refines
              details through dialogue, mirroring human interaction.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurStory;
