import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { GetInTouch } from "../components";

const GetInTouchPage = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <GetInTouch />;
};

export default GetInTouchPage;
