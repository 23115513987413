// Styles
import { NavLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import Styles from "./styles.module.scss";

const Nav = () => {
  const navLinkStyles = ({ isActive }) => {
    return {
      display: "flex",
      alignItems: "center",
      height: "30px",
      // marginTop: isActive ? "10px" : null,
      color: isActive ? "#564CEC" : "#222",
      borderBottom: "2px solid transparent",
      // transition: isActive ? 'all .4s ease-in' : null,
    };
  };

  return (
    <nav className={`${Styles.nav}`}>
      <ul>
        <li>
          <NavHashLink
            className={Styles.nav_link}
            style={navLinkStyles}
            smooth
            to={"/#aboutus"}
          >
            {"Solution"}
          </NavHashLink>
        </li>
        <li>
          <NavLink
            to="/about-us"
            className={Styles.nav_link}
            style={navLinkStyles}
          >
            {"About us"}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/cases"
            className={Styles.nav_link}
            style={navLinkStyles}
          >
            {"Case studies"}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/join-us"
            className={Styles.nav_link}
            style={navLinkStyles}
          >
            {"Careers"}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/get-in-touch"
            className={Styles.nav_link}
            style={navLinkStyles}
          >
            {"Get in touch"}
          </NavLink>
        </li>

        <li>
          <NavLink to="/blog" className={Styles.nav_link} style={navLinkStyles}>
            {"Blog"}
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
