// Mui
import { NavLink } from "react-router-dom";
// Styles
import Styles from "./styles.module.scss";

import Ivan_main from "../../theme/assets/images/Ivan_main.png";
import Oksana_main from "../../theme/assets/images/Oksana_main.png";
import Oleksandr_main from "../../theme/assets/images/Oleksandr_main.png";
import Dmytro_main from "../../theme/assets/images/Dmytro_main.png";
import Kristina_main from "../../theme/assets/images/Kristina_main.png";
import Aki7 from "../../theme/assets/images/aki7.png";
const BasicInfo = () => {
  return (
    <section className={Styles.container}>
      <div className={Styles.main_img} />
      <div className={Styles.content_wrapper}>
        <div className={Styles.info}>
          <h1 className={Styles.main_title}>
            MEET AKI7 - YOUR <br />
            AI-POWERED LLM ASSISTANT
          </h1>
          <h2 className={Styles.main_subtitle}>
            Transform your routine with an intelligent task delegation solution.
            Employ a customized digital AI for your business – human-like,
            efficient, and agile.
          </h2>
          <NavLink to="/get-in-touch" className={Styles.contact_button}>
            <div>
              <span className={Styles.contact_button_text}>
                {"Get in touch"}
              </span>
              <div>
                <div className={Styles.contact_button_blur} />
                <div className={Styles.contact_button_bg} />
              </div>
            </div>
          </NavLink>
        </div>
        <div className={Styles.team}>
          {/* <img className={Styles.ava_ivan} src={Ivan_main} alt="Ivan" />
          <img
            className={Styles.ava_kristina}
            src={Kristina_main}
            alt="Kristina"
          />
          <img className={Styles.ava_oksana} src={Oksana_main} alt="Oksana" />
          <img
            className={Styles.ava_oleksandr}
            src={Oleksandr_main}
            alt="Oleksander"
          />
          <img className={Styles.ava_dmytro} src={Dmytro_main} alt="Dmytro" /> */}
          <img className={Styles.aki7_img} src={Aki7} alt="Aki7" />
        </div>
      </div>
    </section>
  );
};

export default BasicInfo;
