// Mui
import { Button } from '@mui/material'
// Styles
import Styles from './styles.module.scss'

const HowWeWork = () => {
	return (
		<section id={'whatToExpect'} className={Styles.wrapper}>
			<div>
				<span className={Styles.shadow_title} />
				<h2 className={Styles.title}>{'How we work'}</h2>
				<div className={Styles.descr}>
					<p>{'We believe that the best solutions are the ones that the business gets quickly.'}</p>
					<p>{'Make a development plan and start building your product right away.'}</p>
				</div>
				<Button href={'#contact'} variant={'contained'} className={Styles.contact_btn}>
					{'Contact Us'}
				</Button>
			</div>
		</section>
	)
}

export default HowWeWork
