// Components
import {
  BasicInfo,
  AreasExpertise,
  Donation,
  TheyTrustUs,
  WhatWeDo,
  Technologies,
  MediasFeedback,
  ClientsFeedback,
  Cases,
  LinkTo,
} from "../components";

import TextGroup from "../components/TextGroup";
import HowItWorks from "../components/HowItWorks";

const MainPage = () => {
  return (
    <>
      <BasicInfo />
      {/* <WhatWeDo /> */}
      {/* <AreasExpertise /> */}
      {/* <Technologies /> */}
      {/* <Cases title={true} /> */}
      {/* <LinkTo buttonText={"See all case studies"} link={"/cases"} /> */}
      <TextGroup />

      {/* <ClientsFeedback /> */}
      <HowItWorks />
      <MediasFeedback />

      <TheyTrustUs />
      {/* <LinkTo buttonText={"Get in touch"} link={"get-in-touch"} /> */}
      {/* <Donation /> */}
    </>
  );
};

export default MainPage;
