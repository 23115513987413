import { useState, useEffect, useRef } from "react";
// Instruments
// import { useInView } from "react-intersection-observer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//Data
import { approaches } from "./data";
// Styles
import Styles from "./styles.module.scss";
//Images
import planImg from "../../theme/assets/images/sectionServices.svg";
import planImgVertical from "../../theme/assets/images/sectionServices_vertical.svg";
import { NavLink } from "react-router-dom";

const Card = ({ data, extList, type, id }) => {
  return (
    <div
      className={`${Styles.card} ${
        type === "impact" ? Styles.blue_card : null
      } ${id === 5 ? Styles.last_card : null}`}
    >
      <h2>{type === "activities" ? "Activities" : "Outcome & Impact"}</h2>
      <ul className={Styles.card_list}>
        {data.map((list_item, index) => {
          return <li key={index}>{list_item}</li>;
        })}
      </ul>
      <ul className={Styles.ext_list}>
        {extList?.map((list_item, index) => {
          return <li key={index}>{list_item}</li>;
        })}
      </ul>
    </div>
  );
};

const WhatWeDo = () => {
  // { ref, inView, entry } = useInView({
  //   /* Optional options */
  //   threshold: 0,
  // }),
  const settings = {
      arrows: false,
      dots: true,
      fade: true,
      autoplay: false,
      infinite: true,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      appendDots: (dots) => <ul> {dots} </ul>,
    },
    sliderRef = useRef(),
    [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // useEffect(() => {
  //   console.log("inView: ", inView);
  //   if (inView) sliderRef.current.slickPlay();
  //   else sliderRef.current.slickPause();
  // }, [inView]);

  useEffect(() => {
    const updateWindowDimensions = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  return (
    <section id={"aboutus"} className={Styles.wrapper}>
      <span className={Styles.shadow_title} />
      <h1 className={Styles.title}>Your journey with us</h1>
      <h2 className={Styles.descr}>
        We can work together even when you don’t have a clear idea for your
        solution.
        <br /> We research your issue, help you build the solution concept,
        advise on the product shape, and offer an ultimate development plan.
      </h2>
      <img
        className={Styles.diagram}
        src={windowWidth >= 768 ? planImg : planImgVertical}
        alt="plan"
      />

      <div className={Styles.slider_wrapper}>
        <Slider
          {...settings}
          ref={sliderRef}
          style={{
            margin: windowWidth >= 768 ? "120px 0 124px" : "60px 0 184px",
          }}
        >
          {approaches.map((item) => {
            return (
              <div key={item.id} className={Styles.carousel_card}>
                <div
                  className={`${Styles.cards_wrapper} ${
                    item.id % 2 === 0 ? Styles.reverse_block : null
                  }`}
                >
                  <div className={Styles.main_info}>
                    <h2>{item.title}</h2>
                    <p>{item.descr}</p>
                    <div className={Styles.questions_wrapper}>
                      {item.questions.map((question, index) => {
                        return (
                          <div className={Styles.question} key={index}>
                            <span className={Styles.icon} />
                            <p>{question}</p>
                          </div>
                        );
                      })}
                    </div>
                    <NavLink
                      to="/get-in-touch"
                      className={Styles.contact_button}
                    >
                      {"Get in touch"}
                    </NavLink>
                  </div>
                  <img
                    src={item.img}
                    alt="team"
                    className={Styles.slide_image}
                  ></img>
                </div>

                <div className={Styles.cards_wrapper}>
                  <Card
                    data={item.activities}
                    extList={item.activitiesList}
                    type={"activities"}
                    id={item.id}
                  />
                  <Card data={item.impacts} type={"impact"} id={item.id} />
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
};

export default WhatWeDo;
