import { useParams } from "react-router-dom";
import { Project } from "../components";

const ProjectPage = () => {
  const { id } = useParams();

  return <Project id={id} />;
};

export default ProjectPage;
