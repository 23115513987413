// Styles
import Styles from './styles.module.scss'

const companies = [
	{
		id: 1,
		company: 'WZMH',
		website: 'https://www.wzmh.com/',
		class: Styles.wzmh_logo,
	},
	{
		id: 2,
		company: 'EVA',
		website: 'https://evacodes.com/',
		class: Styles.eva_logo,
	},
	{
		id: 3,
		company: 'GPNow',
		website: 'https://www.gpnow.net/about/our-company/',
		class: Styles.gp_now_logo,
	},
	{
		id: 4,
		company: 'Farm to Plate',
		website: 'https://www.farmtoplate.io/contact-us/',
		class: Styles.farm_plate_logo,
	},
	{
		id: 5,
		company: 'Peer social Foundation',
		website: 'https://peer.social/',
		class: Styles.peer_social_logo,
	},
	{
		id: 6,
		company: 'It stand for Ukraine',
		website: 'https://www.itstandforua.com/en',
		class: Styles.itsfu_logo,
	},
	{
		id: 7,
		company: 'Proximity Learning',
		website: 'https://www.proxlearn.com/',
		class: Styles.prox_learn_logo,
	},
	{
		id: 8,
		company: 'sparkbird',
		website: 'https://www.wzmh.com/sparkbird/about/',
		class: Styles.sparkbird_logo,
	},
]

const TheyTrustUs = () => {
	return (
		<section className={Styles.wrapper}>
			<div className={Styles.title_container}>
				<span className={Styles.shadow_title} />
				<h1 className={Styles.title}>{'They trust us'}</h1>
			</div>

			<div className={Styles.grid}>
				<div className={`${Styles.grid_row} ${Styles.primary}`}>
					{companies.map((item) => {
						return (
							<a key={item.id} href={item.website} target="_blank" rel="noreferrer">
								<div className={Styles.company}>
									<span className={`${Styles.company_logo} ${item.class}`} />
								</div>
							</a>
						)
					})}
				</div>
				<div className={`${Styles.grid_row} ${Styles.secondary}`}>
					{companies.map((item) => {
						return (
							<a key={item.id} href={item.website} target="_blank" rel="noreferrer">
								<div className={Styles.company}>
									<span className={`${Styles.company_logo} ${item.class}`} />
								</div>
							</a>
						)
					})}
				</div>
			</div>
		</section>
	)
}

export default TheyTrustUs
