import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { JoinUs } from "../components";

const JoinUsPage = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <JoinUs />;
};

export default JoinUsPage;
