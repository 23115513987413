//Core
import { useRef } from "react";
//Styles
import Styles from "./styles.module.scss";

const Card = ({ item }) => {
  return (
    <div
      onClick={() => {
        console.log("item", item);
      }}
      className={Styles.team_card}
    >
      <div className={item.class} />
      <div
        onClick={() => {
          console.log("item 2", item);
        }}
        className={Styles.info_mask}
      >
        <div
          onClick={() => {
            console.log("item 3", item);
          }}
          className={Styles.card_info}
        >
          <div
            onClick={() => {
              console.log("item 4", item);
            }}
            className={item.class}
          />
          <div className={Styles.corner_image} />

          <h4
            onClick={() => {
              console.log("item 5", item);
            }}
          >
            {item.descr}
          </h4>

          <div
            onClick={() => {
              console.log("item 6", item);
            }}
          >
            <p>{item.name}</p>

            <div className={Styles.contact_wrapper}>
              <p>{item.position}</p>

              <a
                onClick={() => {
                  console.log("a", item.linkedIn);
                }}
                href={item.linkedIn}
                target={"_blank"}
              ></a>
            </div>
          </div>
        </div>
      </div>

      <div className={Styles.mobile_info}>
        <h4 className={Styles.descr}>{item.descr}</h4>

        <p className={Styles.name}>{item.name}</p>

        <div className={Styles.contact_wrapper}>
          <p>{item.position}</p>
          <a href={item.linkedIn} target={"_blank"}></a>
        </div>
      </div>
    </div>
  );
};

const team = [
  {
    id: 1,
    name: "Ivan Kosyuk",
    position: "Founder & CEO",
    descr: "Architecture and development of the company",
    linkedIn: "https://www.linkedin.com/in/ivan-kosyuk-82a05b247/",
    class: Styles.card_avatar_ik,
  },
  {
    id: 2,
    name: "Oksana Basova",
    position: "Chief of BA/PM department",
    descr: "Transferring business needs to working solutions",
    linkedIn: "https://www.linkedin.com/in/oksana-basova/",
    class: Styles.card_avatar_ob,
  },
  {
    id: 3,
    name: "Dmitry Bagow",
    position: "CTO",
    descr: "Technical planning and managing the execution",
    linkedIn: "https://www.linkedin.com/in/dimabagow/",
    class: Styles.card_avatar_db,
  },
  {
    id: 3,
    name: "Yelyzaveta Kholod",
    position: "CBD",
    descr: "Business Development & Sales Management",
    linkedIn: "https://www.linkedin.com/in/yelyzavetakholod/",
    class: Styles.card_avatar_yk,
  },
];

const AboutUs = () => {
  const ref = useRef(null);

  const scroll = (scrollOffset) => {
    ref.current.scrollBy({
      left: scrollOffset,
      behavior: "smooth",
    });
  };

  return (
    <section id={"aboutus"} className={Styles.container}>
      <div className={Styles.wrapper}>
        <div className={Styles.header_wrapper}>
          <span className={Styles.header_icon} />

          <h1 className={Styles.title}>{"Leadership"}</h1>
        </div>

        <span className={Styles.block_background} />
        <div className={Styles.scroll_wrapper}>
          <div className={Styles.team} ref={ref}>
            {team.map((item) => {
              return <Card key={item.id} item={item} />;
            })}
          </div>

          <button className={Styles.left_button} onClick={() => scroll(-284)}>
            <span />
          </button>
          <button className={Styles.right_button} onClick={() => scroll(284)}>
            <span />
          </button>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
