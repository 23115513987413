// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__WwlX3 {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n  min-height: 100vh;\n  overflow-y: hidden;\n  overflow-x: hidden;\n  justify-content: space-between; }\n\n.styles_main_content__VucnX {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: calc(100% - 226px); }\n\n@media (min-width: 320px) and (max-width: 768px) {\n  .styles_main_content__VucnX {\n    height: calc(100% - 218px); } }\n", "",{"version":3,"sources":["webpack://./src/components/Main/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,8BAA8B,EAAA;;AAGhC;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,0BAA0B,EAAA;;AAO5B;EACE;IACE,0BAA0B,EAAA,EAC3B","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n  min-height: 100vh;\n  overflow-y: hidden;\n  overflow-x: hidden;\n  justify-content: space-between;\n}\n\n.main_content {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: calc(100% - 226px);\n  // margin-top: 80px;\n}\n\n@media (min-width: 768px) and (max-width: 1024px) {\n}\n\n@media (min-width: 320px) and (max-width: 768px) {\n  .main_content {\n    height: calc(100% - 218px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__WwlX3",
	"main_content": "styles_main_content__VucnX"
};
export default ___CSS_LOADER_EXPORT___;
