// Routing
import { Routes, Navigate, Route } from "react-router-dom";
// Components
import { Main } from "./components";
// Pages
import {
  AboutUsPage,
  GetInTouchPage,
  JoinUsPage,
  MainPage,
  PortfolioPage,
  ProjectPage,
} from "./pages";
import Blog from "./components/Blog";
import BlogPage from "./pages/BlogPage";
import BlogDescriptionPage from "./pages/BlogDescriptionPage";
import JoinUsDesc from "./components/JoinUsDescription";
import JoinUsDescPage from "./pages/JoinUsDescPage";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Main headerBorder={false} />}>
        <Route index element={<MainPage />} />
      </Route>

      <Route path="/about-us" element={<Main headerBorder={true} />}>
        <Route index element={<AboutUsPage />} />
      </Route>

      <Route path="/cases" element={<Main headerBorder={true} />}>
        <Route index element={<PortfolioPage />} />
      </Route>

      <Route path="/case/:id" element={<Main />}>
        <Route index element={<ProjectPage />} />
      </Route>

      <Route path="/join-us" element={<Main headerBorder={true} />}>
        <Route index element={<JoinUsPage />} />
      </Route>

      <Route path="/join-us/:id" element={<Main />}>
        <Route index element={<JoinUsDescPage />} />
      </Route>

      <Route path="/get-in-touch" element={<Main headerBorder={true} />}>
        <Route index element={<GetInTouchPage />} />
      </Route>

      <Route path="/blog" element={<Main headerBorder={true} />}>
        <Route index element={<BlogPage />} />
      </Route>

      <Route path="/blog/:id" element={<Main />}>
        <Route index element={<BlogDescriptionPage />} />
      </Route>

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default App;
