import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Portfolio } from "../components";

const PortfolioPage = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <Portfolio />;
};

export default PortfolioPage;
