// Styles
import Styles from "./styles.module.scss";

const TextGroup = (props) => {
  return (
    <>
      <section className={Styles.container}>
        <div className={Styles.wrapper}>
          <span
            className={`${Styles.shadow_title_core} ${Styles.shadow_title}`}
          />
          <h1 className={Styles.title}>Core Principle</h1>
          <h2 className={Styles.info_question}>
            Unlock the Power of Intelligent Automation
          </h2>
          <div className={Styles.info_message_wrapper}>
            <h2 className={Styles.info_message}>
              Elevate your team's productivity with Aki7, our cutting-edge AI
              system designed to integrate into your workflow seamlessly. Acting
              as a proactive collaborator for both your team and managers, Aki7
              goes beyond simple automation, becoming an extension of your
              workforce. 
            </h2>
            <h2 className={Styles.info_message}>
              Instruct Aki7 with tasks, and experience effective, swift, and
              human-like execution. Aki7 efficiently replicates your employees'
              knowledge, ensuring complete transparency and control for optimal
              innovation. 
            </h2>
            <h2 className={Styles.info_message}>
              Get an AI companion who is not just a tool but a second pair of
              hands, ready to enhance your team's capabilities.
            </h2>
          </div>
        </div>
      </section>
      <section className={Styles.container}>
        <div className={Styles.wrapper}>
          <span
            className={`${Styles.shadow_title_value} ${Styles.shadow_title}`}
          />
          <h1 className={Styles.title}>Value Creation</h1>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>
              <h2 className={Styles.info_question}>
                Transform Your Operations with Precision 
              </h2>
              <div className={Styles.info_message_wrapper}>
                <h2 className={Styles.info_message}>
                  Aki7 is designed to streamline your daily operations by
                  automating repetitive tasks across various areas such as
                  client support, recruiting, sales, onboarding, and knowledge
                  management. 
                </h2>
                <h2 className={Styles.info_message}>
                  Naturally integrated into your business processes, our AI
                  communicates and understands like a human, utilizing your
                  existing systems. It consistently upholds high work standards,
                  ensures transparency, and adapts to changes. This scalable
                  system learns the nuances of your business, adjusting to
                  evolving processes and providing insights for day-to-day
                  operations. 
                </h2>
              </div>
            </div>
            <div>
              <h2 className={Styles.info_question}>
                Unleash Creativity and Innovation
              </h2>
              <h2 className={Styles.info_message}>
                Free up your creative team members and decision-makers from
                routine chores so they delve into the process of innovations and
                ideas. With Aki7, you can effortlessly delegate tasks, allowing
                for a fast and error-free implementation of new approaches.
              </h2>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TextGroup;
