import { useState, useRef } from "react";
// Instruments
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//Router
import { NavLink } from "react-router-dom";
// Styles
import Styles from "./styles.module.scss";
// Images
import numuw_desk from "../../theme/assets/images/numuw_desktop.png";
import numuw_mob from "../../theme/assets/images/numuw_phone.png";

import tornado_desk from "../../theme/assets/images/tornado_desk.png";
import tornado_mob from "../../theme/assets/images/tornado_phone1.png";

import bachu_desk from "../../theme/assets/images/bachu_desktop.png";
import bachu_mob from "../../theme/assets/images/bachu_phone2.png";

import forpost_desk from "../../theme/assets/images/forpost_desktop.png";
import forpost_mob from "../../theme/assets/images/forpost_phone3.png";

import legion_desk from "../../theme/assets/images/legion_desktop.png";
import legion_mob from "../../theme/assets/images/legion_phone1.png";

import saveUA_desk from "../../theme/assets/images/saveUA_desktop.png";
import saveUA_mob from "../../theme/assets/images/saveUA_mob.png";

const slides = [
  {
    id: 1,
    title: "Numuw",
    descr:
      "Numuw is a healthcare web app with the best user experience that allows the user to choose a doctor, make an appointment, consult with a doctor online, and upload medical history.",
    images: [numuw_mob, numuw_desk],
  },
  {
    id: 2,
    title: "Tornado",
    descr:
      "A website with mobile and tablet adaptation. We created back-end architecture, developed a specialized smart contract and integrated Metamask to allow our client to begin sales of their NFT product.",
    images: [tornado_mob, tornado_desk],
  },
  {
    id: 3,
    title: "Bachu",
    descr:
      "Web and mobile apps for reporting the movements of Russian military vehicles, weapons, troops,or saboteurs on Ukrainian territory. ",
    images: [bachu_mob, bachu_desk],
  },
  {
    id: 4,
    title: "Forpost",
    descr:
      "Website and mobile app (Android and IOS). With this app, you can check whether the vehicle is a security threat by photographing the license plate or simply entering the letters and digits you saw.",
    images: [forpost_mob, forpost_desk],
  },
  {
    id: 5,
    title: "International Legion ",
    descr:
      "A website and chatbot designed for non-citizens willing to volunteer for military service in Ukraine. Our solutions assist volunteers in securing the necessary paperwork to join the Armed Forces of Ukraine.",
    images: [legion_mob, legion_desk],
  },
  {
    id: 6,
    title: "SaveUA",
    descr:
      "The war has posed a host of dire humanitarian problems. SaveUA bot (Telegram and Viber) allows you to locate, receive or provide help in different regions across Ukraine.",
    images: [saveUA_mob, saveUA_desk],
  },
];

const Cases = (props) => {
  const [currentSlide, setCurrentSlide] = useState(1),
    settings = {
      dots: false,
      fade: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      afterChange: (current) => setCurrentSlide(current + 1),
    },
    slider = useRef();

  return (
    <section id={"cases"} className={Styles.container}>
      <span className={Styles.case_backgr} />
      <span className={Styles.backgr_oval} />

      <div className={Styles.wrapper}>
        {props.title && (
          <>
            <span className={Styles.shadow_title} />
            <h1 className={Styles.title}>{"Case studies"}</h1>
          </>
        )}

        <div className={Styles.carousel_wrapper}>
          <Slider {...settings} ref={slider}>
            {slides.map((item) => {
              return (
                <div key={item.id} className={Styles.carousel_card}>
                  <div className={Styles.card_info}>
                    <header>
                      <h3 className={Styles.info_title}>{item.title}</h3>
                      <p className={Styles.info_text}>{item.descr}</p>
                    </header>
                    <footer>
                      <NavLink
                        to={`/case/` + `${currentSlide}`}
                        className={Styles.more_info}
                      >
                        {`More information`}
                      </NavLink>
                      <div className={Styles.slides_counter}>
                        <div>
                          <button
                            className={Styles.arrow + " " + Styles.prev_arrow}
                            onClick={() => slider?.current?.slickPrev()}
                          >
                            <span />
                          </button>
                          <button
                            className={Styles.arrow + " " + Styles.next_arrow}
                            onClick={() => slider?.current?.slickNext()}
                          >
                            <span />
                          </button>
                        </div>
                        <div>
                          <span>{`0${currentSlide}/`}</span>
                          <span>{`0${slides.length}`}</span>
                        </div>
                      </div>
                    </footer>
                  </div>
                  <div className={Styles.card_img}>
                    {item.images.map((img, index) => {
                      return (
                        <img
                          key={index}
                          className={Styles.slide_img}
                          src={img}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Cases;
