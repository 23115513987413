// Core
import { useState } from "react";
// Instruments
import emailjs from "emailjs-com";
// Mui
import { Button, Alert, Snackbar } from "@mui/material";
// Styles
import Styles from "./styles.module.scss";
// Components
import Spinner from "../Spinner";

import { NavLink } from "react-router-dom";
import useCareerApi from "../../api/career/careerApi";
import CareerModal from "../CareerModal";

const JoinCard = ({ title, desc, noCareers, onOpen, id }) => {
  return (
    <div className={Styles.career_card}>
      <div className={Styles.text_wrapper}>
        <h2
          style={{
            display: noCareers ? "none" : "block",
          }}
          className={Styles.career_title}
        >
          {title}
        </h2>
        <p className={Styles.career_p}>{desc}</p>
      </div>

      {!noCareers && (
        <NavLink to={`/join-us/${id}`} className={Styles.career_apply}>
          <span className={Styles.apply_text}>{"Apply now"}</span>
          <div className={Styles.apply_bg} />
          {/* <div className={Styles.apply_blur} /> */}
        </NavLink>
      )}
      {noCareers && (
        <button onClick={onOpen} className={Styles.career_apply}>
          <span className={Styles.apply_text}>{"Apply now"}</span>
          <div className={Styles.apply_bg} />
          {/* <div className={Styles.apply_blur} /> */}
        </button>
      )}
    </div>
  );
};

const JoinUs = () => {
  // const [loading, setLoading] = useState(false);
  // const [open, setOpen] = useState(false);
  // const [answer, setAnswer] = useState({});
  // const [docs, setDocs] = useState([]);
  // const specialities = [
  //   "Advanced Tech",
  //   "Big Data",
  //   "Business Analysis",
  //   "Business Operations",
  //   "Data Science",
  //   "Delivery Leadership",
  //   "Finance",
  //   "Global Talent Operations",
  //   "Human Resources",
  //   "Information Security",
  //   "IT",
  //   "Learning & Certification",
  //   "Management",
  //   "Marketing",
  //   "Platforms",
  //   "Product Management",
  //   "Project Management",
  //   "Sales",
  //   "Software Development",
  //   "Software Testing",
  //   "System Architecture",
  //   "System Integration",
  //   "Technical Communication",
  //   "Technology Consulting",
  //   "UI/UX",
  // ];

  // const onFileChange = (event) => {
  //   setAnswer("");
  //   const inputFiles = document.getElementById("files").files;
  //   const files = [...inputFiles];

  //   if (inputFiles.length > 5) {
  //     setAnswer({ status: 500, text: "Only 5 files are allowed to upload." });
  //     setOpen(true);
  //     return;
  //   }

  //   const fileSizeSum = files.reduce((previousValue, currentValue) => {
  //     return previousValue + currentValue.size;
  //   }, 0);
  //   if (fileSizeSum > 20000000) {
  //     setAnswer({ status: 500, text: "Maximum files size 20mb" });
  //     setOpen(true);
  //     return;
  //   }

  //   setDocs([...inputFiles]);
  // };

  // const deleteAllFiles = () => {
  //   document.getElementById("files").value = null;
  //   setDocs([]);
  // };

  // const alertSettings = {
  //   vertical: "top",
  //   horizontal: "center",
  // };

  // const submitForm = (event) => {
  //   event.preventDefault();
  //   setLoading(true);
  //   emailjs
  //     .sendForm(
  //       process.env.REACT_APP_HR_SERVICE_ID,
  //       process.env.REACT_APP_HR_TEMPLATE_ID,
  //       event.target,
  //       process.env.REACT_APP_USER_ID
  //     )
  //     .then((result) => {
  //       if (result.status === 200) {
  //         setAnswer({
  //           status: result.status,
  //           text: "Message Sent, We will get back to you shortly",
  //         });
  //         setOpen(true);
  //         event.target.reset();
  //         document.getElementById("files").value = null;
  //         setDocs([]);
  //         setLoading(false);
  //       }

  //       return result.status;
  //     })
  //     .catch((error) => {
  //       if (error.status === 400 || error.status === 0) {
  //         setAnswer({
  //           status: error.status,
  //           text: "An error occurred, Please try again",
  //         });
  //         setOpen(true);
  //         event.target.reset();
  //         document.getElementById("files").value = null;
  //         setDocs([]);
  //         setLoading(false);
  //       }
  //     });
  // };

  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }

  //   setOpen(false);
  // };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const onClose = () => {
    setIsModalOpen(false);
  };
  const onOpen = () => {
    setIsModalOpen(true);
  };
  const { data, status } = useCareerApi();
  if (status === "loading") {
    return (
      <div
        style={{
          height: "64.4vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          justifySelf: "center",
        }}
      >
        <div className={Styles.loader}></div>
      </div>
    );
  }
  //status === "success" && data.length !== 0
  return (
    <div className={Styles.container}>
      <CareerModal onClose={onClose} isModalOpen={isModalOpen} />
      <span className={Styles.bg_image} />
      <div className={Styles.title_container}>
        <span className={Styles.shadow_title} />
        <h1 className={Styles.title_main}>{"Careers"}</h1>
      </div>
      <div className={Styles.wrapper}>
        {status === "success" && data.length !== 0 ? (
          data.map((career) => (
            <JoinCard
              title={career.attributes.title}
              desc={career.attributes.description}
              id={career.id}
              key={career.id}
            />
          ))
        ) : (
          <JoinCard
            title={"Frontend Developer"}
            noCareers={true}
            onOpen={onOpen}
            desc={
              "We always need talented people on board! Feel free to share your CV. Let's connect and explore potential synergies."
            }
          />
        )}
      </div>
    </div>
  );
};

export default JoinUs;
