//Images
import teamImg from "../../theme/assets/images/team.png";
import team_mobile from "../../theme/assets/images/team_mobile.png";

import refineConcept from "../../theme/assets/images/refine_concept.png";
import prioritizePlan from "../../theme/assets/images/prioritize_plan.png";
import buildLaunch from "../../theme/assets/images/build_launch.png";
import improve from "../../theme/assets/images/improve.png";

export const approaches = [
  {
    id: 1,
    title: "HARD to FIND the solution?",
    descr:
      "You realize your business has an need but you have no clue what to do with it",
    questions: [
      "How to approach the task?",
      "What are the options for dealing with it?",
    ],
    activities: [
      "Market and Technical analysis",
      "Analysis of the business processes involved in the solution",
      "Market Identification",
    ],
    impacts: [
      "Business model and objectives of your solution",
      "Description of the business processes involved in the solution",
      "Description of end users and their “sore points”",
    ],
    img: window.innerWidth > 420 ? teamImg : team_mobile,
  },
  {
    id: 2,
    title: "Refine the concept",
    descr:
      "You understand the specifics of your issue and what kind of solution you need.",
    questions: [
      "How exactly is this solution supposed to work for me?",
      "What changes do I want to implement?",
      "Which of my “pains” are the most acute?",
    ],
    activities: [
      "Functional analysis: a breakdown of essential features of your solution, their goals, and value",
      "Validation: interviewing the group that will interact with the solution; potential users will provide feedback about what’s most important for them.",
    ],
    impacts: [
      "Feature breakdown with a detailed description of how these features will work and which ones to include in the first release.",
      "You have a clear understanding of the user requirements for your product.",
    ],
    img: refineConcept,
  },
  {
    id: 3,
    title: "Prioritize & Plan",
    descr:
      "You know what’s important for the first release (MVP) and what can be implemented later. You need the tech team to build an MVP.",
    questions: [
      "What is the technical description of the solution?",
      "How much will each development phase cost?",
      "How long will each development phase take?",
    ],
    activities: [
      "Creating a roadmap for implementing your solution for the optimal cost.",
      "Estimating approximate cost and lengths of each development phase.",
      "Drawing up technical documentation based on the solution’s functional description.",
    ],
    impacts: [
      "A budget that shows the approximate development cost, payment details, and distribution of possible risks.",
      "All details to start the development: solution’s flow, feature breakdown, quality attributes (technologies, integrations, expected server loads, etc.)",
    ],
    img: prioritizePlan,
  },
  {
    id: 4,
    title: "Build & Launch",
    descr:
      "You have a vision of the solution and a technical instruction for its development. You need a first release to make sure your solution satisfies users’ needs.",
    questions: [
      "Who can and will build my solution?",
      "What are the risks, and how will they be divided between the contractor and my business?",
      "What intermediate results can I expect?",
    ],
    activities: [
      "Development and release (using no-code development platforms or creating a custom code)",
      "Alpha & Beta Testing on target users",
      "Updating the development plan",
      "Modifying the technical instruction",
    ],
    impacts: [
      "First release with core features",
      "Customer feedback report (your hypotheses VS results)",
      "Improvements for the next release",
    ],
    img: buildLaunch,
  },
  {
    id: 5,
    title: "Improve",
    descr:
      "You have an existing solution or an MVP (already tested on target users), and you have made changes to the development plan.",
    questions: [
      "How do we analyze and improve user experience?",
      "How do we build a product development plan?",
      "How do we maintain the product?",
    ],
    activities: [
      "Analyzing the feedback from the market",
      "Advising on feature prioritization based on technical aspects",
      `Creating support & maintenance plan for:`,
    ],
    activitiesList: [
      "Gathering and analyzing user feedback |",
      "Keeping software packages up to date and compliant with partner APIs",
      "Swiftly implementing minor changes",
    ],
    impacts: [
      "Feature prioritization for the next releases",
      "A list of regular steps to keep your product functioning",
    ],
    img: improve,
  },
];
