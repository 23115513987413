// Styles
import Styles from "./styles.module.scss";
// Images
import logo from "../../theme/assets/icons/logo.svg";
import { NavHashLink } from "react-router-hash-link";
import { NavLink } from "react-router-dom";

import Fb from "../../theme/assets/icons/fb.svg";
import Tg from "../../theme/assets/icons/tg.svg";
import In from "../../theme/assets/icons/in.svg";

const Footer = () => {
  return (
    <footer className={Styles.footer}>
      <div className={Styles.wrapper}>
        <a href={"/"} className={Styles.logo}>
          <img src={logo} alt={"logo"} />
        </a>
        <div className={Styles.footer_contacts}>
          <div>
            <NavHashLink smooth to={"/#aboutus"}>
              {"Solution"}
            </NavHashLink>
            <NavLink to="/about-us">{"About us"}</NavLink>
            <NavLink to="/cases">{"Case studies"}</NavLink>
            <div className={Styles.midle_column_phone}>
              <NavLink to="/join-us">{"Careers"}</NavLink>
              <div className={Styles.mail_wrapper}>
                <NavHashLink to="/get-in-touch">{"Contact Us"}</NavHashLink>
                <a
                  href="mailto:info@gart.tech"
                  className={Styles.contacts_email}
                >
                  {"info@gart.tech"}
                </a>
              </div>
              <NavLink to="/blog">{"Blog"}</NavLink>
            </div>
          </div>
          <div className={Styles.midle_column}>
            <NavLink to="/join-us">{"Careers"}</NavLink>
            <div className={Styles.mail_wrapper}>
              <NavHashLink to="/get-in-touch">{"Contact Us"}</NavHashLink>
              <a href="mailto:info@gart.tech" className={Styles.contacts_email}>
                {"info@gart.tech"}
              </a>
            </div>
            <NavLink to="/blog">{"Blog"}</NavLink>
          </div>
          <div className={Styles.credits}>
            <span>{"LLC Name: Welp Ukraine"}</span>
            <span>{"Address: Ukraine, Kyiv, Mechnikova street 4/1,01133"}</span>
            <div className={Styles.phone_wrapper}>
              <span>{"Contact phone number:"}</span>
              <span className={Styles.contacts_email}>{"+380675555572"}</span>
            </div>
          </div>
        </div>
        <ul className={Styles.footer_socials}>
          <li className={Styles.socials}>
            <a
              href={"https://www.linkedin.com/company/garttech"}
              target={"_blank"}
              rel="noreferrer"
              className={Styles.in}
            >
              <img src={In} alt="social_icon" />
            </a>
          </li>
          {/* <li className={Styles.socials}>
            <a
              href={"https://www.facebook.com/GartTechnology"}
              target={"_blank"}
              rel="noreferrer"
              className={Styles.fb}
            >
              <img src={Fb} alt="social_icon" />
            </a>
          </li> */}
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
