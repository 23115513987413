import { useParams } from "react-router-dom";
import Blog from "../components/Blog";

const BlogPage = () => {
  const { id } = useParams();

  return <Blog />;
};

export default BlogPage;
