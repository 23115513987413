// Core
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
// Styles
import Styles from "./styles.module.scss";

const SideNav = ({ sideNavIsOpen, setSideNavIsOpen }) => {
  const dropdown = sideNavIsOpen
    ? Styles.side_nav_menu_open
    : Styles.side_nav_menu;
  const shadow = sideNavIsOpen
    ? Styles.side_nav_shadow
    : Styles.side_nav_shadow_hide;

  const navLinkStyles = ({ isActive }) => {
    return {
      borderLeft: isActive ? "5px solid #302BDA" : "white",
    };
  };

  return (
    <section className={Styles.side_nav}>
      <div className={shadow} />
      <div
        onClick={() => setSideNavIsOpen(!sideNavIsOpen)}
        className={`${
          !sideNavIsOpen ? Styles.side_nav_burger : Styles.side_nav_close
        }`}
      />
      <div className={dropdown}>
        <ul>
          <li>
            <NavHashLink
              smooth
              to={"/#aboutus"}
              onClick={() => setSideNavIsOpen(!sideNavIsOpen)}
            >
              {"Solution"}
            </NavHashLink>
          </li>
          <li>
            <NavLink
              to="/about-us"
              style={navLinkStyles}
              onClick={() => setSideNavIsOpen(!sideNavIsOpen)}
            >
              {"About us"}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/cases"
              style={navLinkStyles}
              onClick={() => setSideNavIsOpen(!sideNavIsOpen)}
            >
              {"Case studies"}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/join-us"
              style={navLinkStyles}
              onClick={() => setSideNavIsOpen(!sideNavIsOpen)}
            >
              {"Careers"}
            </NavLink>
          </li>
          <li>
            <NavHashLink
              to="/get-in-touch"
              style={navLinkStyles}
              onClick={() => setSideNavIsOpen(!sideNavIsOpen)}
            >
              {"Get in touch"}
            </NavHashLink>
          </li>
          <li>
            <NavHashLink
              to="/blog"
              style={navLinkStyles}
              onClick={() => setSideNavIsOpen(!sideNavIsOpen)}
            >
              {"Blog"}
            </NavHashLink>
          </li>
        </ul>

        <ul className={Styles.footer_socials}>
          <li className={Styles.socials}>
            <a
              href={"https://www.linkedin.com/company/garttech"}
              target={"_blank"}
              rel="noreferrer"
              className={Styles.in}
            />
          </li>
          {/* <li className={Styles.socials}>
            <a
              href={"https://www.facebook.com/GartTechnology"}
              target={"_blank"}
              rel="noreferrer"
              className={Styles.fb}
            />
          </li>
          <li className={Styles.socials}>
            <a
              href={"https://t.me/ithub_ua"}
              target={"_blank"}
              rel="noreferrer"
              className={Styles.tg}
            />
          </li> */}
        </ul>

        <div className={Styles.email}>{"info@gart.tech"}</div>

        {/* <div className={Styles.contacts}>
					<p>{'+49 1575 208 30 98'}</p>
					<p>{'+1 647 928 0691'}</p>
				</div> */}
      </div>
    </section>
  );
};

export default SideNav;
