// Core
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// Styles
import Styles from "./styles.module.scss";
// Images
import block_01 from "../../theme/assets/images/block_01.png";
import block_02 from "../../theme/assets/images/block_02.png";
import block_03 from "../../theme/assets/images/block_03.png";

import sl1 from "../../theme/assets/images/slider_bg1.png";

//Data
import { slides } from "./data";
import { cards } from "./data";

const Line = ({ id }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 232 201"
      fill="none"
      className={`${Styles[`line${id + 1}`]} ${Styles.line}`}
    >
      <path
        d="M182.197 2C182.197 2 176.052 117.59 53.0782 188.437"
        stroke="#E9EBF4"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="0 11"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M55.466 196.231L56.0499 193.597L48.4885 192.559L50.1402 185.108L47.4673 184.741L45.5236 193.509C45.3624 194.237 45.83 194.909 46.5681 195.01L55.466 196.231Z"
        fill="#DBDBDB"
      />
    </svg>
  );
};

const Project = ({ id }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const currentCase = cards.find((card) => card.id === Number(id));

  const handleCardTitle = (id) => {
    switch (id) {
      case 1:
        return "Challenges";
      case 2:
        return "Goal";
      case 3:
        return "Solution";
      case 4:
        return "Result";
    }
  };

  return (
    <section className={Styles.container}>
      <div className={Styles.main_img} />
      <div className={Styles.main_img_2} />

      <div className={Styles.wrapper}>
        <div className={Styles.title_wrapper}>
          <div className={Styles.shadow_title}>Title</div>
          <div className={Styles.title_main}>{currentCase.mainTitle}</div>
        </div>

        {currentCase.elements.map((card, index) => {
          return (
            <div className={Styles.case_card}>
              <div className={Styles.case_card_text_wrapper}>
                <div className={Styles.shadow_title}>
                  {handleCardTitle(card.id)}
                </div>
                <div className={Styles.case_card_title}>
                  {handleCardTitle(card.id)}
                </div>
                <div className={Styles.case_card_info}>{card.text}</div>
              </div>

              <div className={Styles.case_card_number}>{`#0${card.id}`}</div>
              <div className="case_card_arrow" />
              {index !== 3 && <Line id={index} />}
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Project;
