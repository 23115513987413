// Styles
import Styles from "./styles.module.scss";
// Routing
import { NavLink } from "react-router-dom";
// Images
import sl1 from "../../theme/assets/images/slider_bg1.png";
import slide_text from "../../theme/assets/images/machine_learning.png";
import web_mob from "../../theme/assets/images/web_mob.png";
import mobile_desk from "../../theme/assets/images/mobile_desk.png";

import tornado from "../../theme/assets/images/tornado.png";
import numuw from "../../theme/assets/images/numuw.png";
import bachu from "../../theme/assets/images/bachu.png";
import forpost from "../../theme/assets/images/forpost.png";
import legion from "../../theme/assets/images/legion.png";

import saveUA from "../../theme/assets/images/saveUA.png";

const slides = [
  {
    id: 1,
    title: "Tornado",
    descr:
      "A website with mobile and tablet adaptation. We created back-end architecture, developed a specialized smart contract and integrated Metamask to allow our client to begin sales of their NFT product.",
    images: tornado,
    appType: mobile_desk,
  },
  {
    id: 2,
    title: "Healthcare platform",
    descr:
      "Numuw is a healthcare web app with the best user experience that allows the user to choose a doctor, make an appointment, consult with the specialist, and upload medical history.",
    images: numuw,
    appType: web_mob,
  },
  {
    id: 3,
    title: "Bachu",
    descr:
      "Web and mobile apps for reporting the movements of Russian military vehicles, weapons, troops,or saboteurs on Ukrainian territory. ",
    images: bachu,
    appType: mobile_desk,
  },
  {
    id: 4,
    title: "Forpost",
    descr:
      "Website and mobile app (Android and IOS). With this app, you can check whether the vehicle is a security threat by photographing the license plate or simply entering the letters and digits you saw.",
    images: forpost,
    appType: web_mob,
  },
  {
    id: 5,
    title: "International Legion ",
    descr:
      "A website and chatbot designed for non-citizens willing to volunteer for military service in Ukraine. Our solutions assist volunteers in securing the necessary paperwork to join the Armed Forces of Ukraine.",
    images: legion,
    appType: mobile_desk,
  },
  {
    id: 6,
    title: "SaveUA",
    descr:
      "The war has posed a host of dire humanitarian problems. SaveUA bot (Telegram and Viber) allows you to locate, receive or provide help in different regions across Ukraine.",
    images: saveUA,
    appType: mobile_desk,
  },
  {
    id: 7,
    title: "Behavioral customer clustering",
    descr:
      "We combined millions of personal and transactional data to develop a model using more than 100 different features to divide existing clients into groups with distinctive behavior.",
    images: sl1,
    appType: slide_text,
  },
];

{
  /* <div className={Styles.slide_info}>
{item.id !== 7 && (
  <NavLink
    to={`/case/` + `${item.id}`}
    className={Styles.more_info}
  >
    <div className={Styles.button_bg}>
      <span>View Case</span>
    </div>
  </NavLink>
)}
<img
  src={item.appType}
  className={Styles.appType_img}
></img>
</div> */
}

const caseCards = [
  { id: 1, title: "Human-like AI Agent for Crypto Exchange Platform" },
  { id: 2, title: "Automated Personal Coach" },
  { id: 3, title: "AI-powered Doctor Assistant" },
  { id: 4, title: "Healthcare Supply Market Facilitator" },
  {
    id: 5,
    title:
      "AI Outstaffing Assistant: Streamlining Lead Responses for IT Company",
  },
];

const Portfolio = () => {
  return (
    <section className={Styles.container}>
      {/* <div className={Styles.bg_image} />
      <div className={Styles.bg_image} />
      <div className={Styles.bg_image} />*/}
      <div className={Styles.bg_image} />

      {/* <div className={Styles.circle_image} />
      <div className={Styles.circle_image_1} />
      <div className={Styles.circle_image_2} /> */}

      <div className={Styles.box_image} />
      <div className={Styles.box_image} />
      {/* <div className={Styles.box_image} />
      <div className={Styles.box_image} />
      <div className={Styles.box_image} />
      <div className={Styles.box_image} />
      <div className={Styles.box_image} /> */}

      <div className={Styles.wrapper}>
        <span className={Styles.shadow_title} />
        <h2 className={Styles.title}>{"Case studies"}</h2>
        {/* {slides.map((item) => {
          return (
            <div key={item.id} className={Styles.carousel_wrapper}>
              <div
                className={`${Styles.carousel_card} ${
                  item.id % 2 === 0 ? Styles.reverse_card : null
                }`}
              >
                <div className={Styles.card_info}>
                  <h3 className={Styles.info_title}>{item.title}</h3>
                  <div className={Styles.info_text}>{item.descr}</div>

                  <div className={Styles.slide_info}>
                    {item.id !== 7 && (
                      <NavLink
                        to={`/case/` + `${item.id}`}
                        className={Styles.more_info}
                      >
                        <div className={Styles.button_bg}>
                          <span>View Case</span>
                        </div>
                      </NavLink>
                    )}
                    <img
                      src={item.appType}
                      className={Styles.appType_img}
                    ></img>
                  </div>
                </div>
                <img
                  src={item.images}
                  className={`${Styles.case_img} ${
                    item.id === 7 ? Styles.last_case_img : null
                  }`}
                ></img>
              </div>
            </div>
          );
        })} */}
        <div className={Styles.case_card_wrapper}>
          {caseCards.map((card) => {
            return (
              <div
                key={card.id}
                className={`${Styles.case_card} ${
                  card.id === 5 ? Styles.case_card_last : null
                }`}
              >
                <div className={Styles.case_card_info}>
                  <h3 className={Styles.case_card_title}>{card.title}</h3>
                  <div className={Styles.slide_info}>
                    {card.id !== 7 && (
                      <NavLink
                        to={`/case/` + `${card.id}`}
                        className={Styles.more_info}
                      >
                        <div className={Styles.button_bg}>
                          <span>View Case</span>
                        </div>
                      </NavLink>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
