import { useParams } from "react-router-dom";
import BlogDescription from "../components/BlogDescription";

const BlogDescriptionPage = () => {
  const { id } = useParams();

  return <BlogDescription id={id} />;
};

export default BlogDescriptionPage;
