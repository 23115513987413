//Core
import { useState } from "react";
// Styles
import Styles from "./styles.module.scss";
//Images
import company_1 from "../../theme/assets/images/ITKeyMedia.png";
import company_2 from "../../theme/assets/images/Maddyness.png";
import company_3 from "../../theme/assets/images/FastCompany.png";

const feedbacks = [
  {
    id: 1,
    media: "ITKeyMedia",
    feedback: `Gart Technology is one of the many newly formed Ukrainian tech companies that look 
      into the future with optimism. Moreover, they are already using their looks specific experience (such as mobilizing forces quickly, 
      managing teams with an increased level of autonomy and flexibility, etc) for commercial purposes.`,
    logo: company_1,
    link: "https://itkey.media/ua-it-hub-volunteer-tech-community-turned-developer-first-tech-service-platform/",
  },
  {
    id: 2,
    media: "Maddyness",
    feedback: `The Gart Technology assembles IT teams with the perfect balance of skills and time availability to meet 
			the demands of businesses. A wide range of skill sets — from machine learning to cybersecurity to app 
			development — are represented in the company.`,
    logo: company_2,
    link: "https://www.maddyness.com/uk/2022/10/26/the-ukrainian-company-supporting-tech-volunteers-at-the-heart-of-the-information-war/",
  },
  {
    id: 3,
    media: "Fast Company",
    feedback: `Gart Technology provides a layer of project management and coordination so that the programmers 
			and designers stay in sync with the project timeline and other needs of the client `,
    logo: company_3,
    link: "https://www.fastcompany.com/90740551/this-company-is-helping-ukrainian-tech-workers-land-contract-gigs",
  },
];

const MediasFeedback = () => {
  const [selectedMedia, setSelectedMedia] = useState(feedbacks[0]);

  return (
    <section className={Styles.wrapper}>
      <span className={Styles.shadow_title} />
      <h2 className={Styles.title}>{"Testimonials"}</h2>

      <div className={Styles.info_container}>
        <div className={Styles.media_wrapper}>
          {feedbacks.map((item) => {
            return (
              <div
                key={item.id}
                className={`${Styles.media_item}`}
                onClick={() => setSelectedMedia(item)}
              >
                <div
                  className={`${
                    item.id === selectedMedia.id
                      ? Styles.active_block
                      : Styles.disabled
                  }`}
                />
                <img src={item.logo} alt={item.media}></img>
              </div>
            );
          })}
        </div>
        <div className={Styles.media_feedback}>
          <div className={Styles.item_title}>
            <h4>{selectedMedia.media}</h4>
            <span className={Styles.quotes} />
          </div>

          <div className={Styles.divider} />

          <h3>{selectedMedia.feedback}</h3>
          <div className={Styles.media_link_wrapper}>
            <a
              href={selectedMedia.link}
              target={"_blank"}
              rel="noreferrer"
              className={Styles.media_link}
            >
              <div>
                <span className={Styles.contact_button_text}>
                  {"Read the article"}
                </span>
                <div>
                  <div className={Styles.contact_button_blur} />
                  <div className={Styles.contact_button_bg} />
                </div>
              </div>
              <div className={Styles.media_link_icon} />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MediasFeedback;
