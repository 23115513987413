// Styles
import Styles from "./styles.module.scss";

const technologies_1 = [
  {
    id: 1,
    name: "Vue",
    className: Styles.vue_logo,
  },
  {
    id: 2,
    name: "TypeScript",
    className: Styles.typescript_logo,
  },
  {
    id: 3,
    name: "React",
    className: Styles.react_logo,
  },
  {
    id: 4,
    name: "Unity",
    className: Styles.unity_logo,
  },
  {
    id: 5,
    name: "AWS Batch",
    className: Styles.aws_batch_logo,
  },
  {
    id: 6,
    name: "Amazon RDS",
    className: Styles.amazon_rds_logo,
  },
  {
    id: 7,
    name: "Swift",
    className: Styles.swift_logo,
  },
];

const technologies_2 = [
  {
    id: 11,
    name: "JavaScript",
    className: Styles.javaScript_logo,
  },
  {
    id: 21,
    name: "Material UI",
    className: Styles.material_ui_logo,
  },
  {
    id: 31,
    name: "Node.js",
    className: Styles.nodeJs_logo,
  },
  {
    id: 41,
    name: "Unreal Engine",
    className: Styles.unreal_engine_logo,
  },
  {
    id: 51,
    name: "Redis",
    className: Styles.redis_logo,
  },
  {
    id: 61,
    name: "Kotlin",
    className: Styles.kotlin_logo,
  },
  {
    id: 71,
    name: "Postman",
    className: Styles.postman_logo,
  },
];

const technologies_3 = [
  {
    id: 11,
    name: "CSS",
    className: Styles.css3_logo,
  },
  {
    id: 21,
    name: "BetterDocs",
    className: Styles.betterDocs_logo,
  },
  {
    id: 31,
    name: "Blender",
    className: Styles.blender_logo,
  },
  {
    id: 41,
    name: "Python",
    className: Styles.python_logo,
  },
  {
    id: 51,
    name: "PostgreSQL",
    className: Styles.postgresql_logo,
  },
  {
    id: 61,
    name: "React Native",
    className: Styles.react_logo,
  },
  {
    id: 71,
    name: "Selenium",
    className: Styles.selenium_logo,
  },
];
const technologies_4 = [
  {
    id: 11,
    name: "GitHub",
    className: Styles.github_logo,
  },
  {
    id: 21,
    name: "Vercel",
    className: Styles.vercel_logo,
  },
  {
    id: 31,
    name: "Angular",
    className: Styles.angular_logo,
  },
  {
    id: 41,
    name: "C++",
    className: Styles.cplusplus_logo,
  },
  {
    id: 51,
    name: "PyTorch",
    className: Styles.pyTorch_logo,
  },
  {
    id: 61,
    name: "Docker",
    className: Styles.docker_logo,
  },
  {
    id: 71,
    name: "FastAPI",
    className: Styles.fastapi_logo,
  },
];

const TechnologyCard = ({ item }) => {
  return (
    <div className={Styles.tech_card}>
      <div className={`${Styles.tech_logo} ${item.className}`}></div>
      {item.name}
    </div>
  );
};

const Technologies = () => {
  return (
    <section className={Styles.wrapper}>
      <div className={Styles.title_container}>
        <span className={Styles.shadow_title} />
        <h1 className={Styles.title}>
          Technologies <br />& Stack
        </h1>
        <p className={Styles.descr}>
          The most popular tech we use. If you request any other technology,
          that is not on the list, please, let us know.
        </p>
      </div>

      <div className={Styles.grid}>
        <div className={Styles.animate_to_right}>
          <div
            className={`${Styles.grid_row} ${Styles.primary}`}
            style={{ top: "0%" }}
          >
            {technologies_1.map((item) => {
              return <TechnologyCard item={item} key={item.id} />;
            })}
          </div>

          <div
            className={`${Styles.grid_row} ${Styles.secondary}`}
            style={{ top: "0%" }}
          >
            {technologies_1.map((item) => {
              return <TechnologyCard item={item} key={item.id} />;
            })}
          </div>
        </div>

        <div className={Styles.animate_to_left}>
          <div
            className={`${Styles.grid_row} ${Styles.primary}`}
            style={{ top: "26%" }}
          >
            {technologies_2.map((item) => {
              return <TechnologyCard item={item} key={item.id} />;
            })}
          </div>

          <div
            className={`${Styles.grid_row} ${Styles.secondary}`}
            style={{ top: "26%" }}
          >
            {technologies_2.map((item) => {
              return <TechnologyCard item={item} key={item.id} />;
            })}
          </div>
        </div>

        <div className={Styles.animate_to_right}>
          <div
            className={`${Styles.grid_row} ${Styles.primary}`}
            style={{ top: "52%" }}
          >
            {technologies_3.map((item) => {
              return <TechnologyCard item={item} key={item.id} />;
            })}
          </div>

          <div
            className={`${Styles.grid_row} ${Styles.secondary}`}
            style={{ top: "52%" }}
          >
            {technologies_3.map((item) => {
              return <TechnologyCard item={item} key={item.id} />;
            })}
          </div>
        </div>

        <div className={Styles.animate_to_left}>
          <div
            className={`${Styles.grid_row} ${Styles.primary}`}
            style={{ top: "78%" }}
          >
            {technologies_4.map((item) => {
              return <TechnologyCard item={item} key={item.id} />;
            })}
          </div>

          <div
            className={`${Styles.grid_row} ${Styles.secondary}`}
            style={{ top: "78%" }}
          >
            {technologies_4.map((item) => {
              return <TechnologyCard item={item} key={item.id} />;
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Technologies;
