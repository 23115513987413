// Styles
import { Link } from "react-router-dom";
import Styles from "./styles.module.scss";

const LinkTo = ({ buttonText, link }) => {
  return (
    <section className={Styles.wrapper}>
      <div className={Styles.link_container}>
        <span className={Styles.massage_img} />
        <span className={Styles.cube_img} />
        <span className={Styles.massage_img} />
        <Link to={link} className={Styles.contact_button}>
          {buttonText}
        </Link>
      </div>
    </section>
  );
};

export default LinkTo;
