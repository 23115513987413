// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_wrapper__fPRz\\+ {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 46px;\n  background-color: var(--footer-bgcolor); }\n\n.styles_info__ZQfKu {\n  display: flex;\n  font-size: var(--primary-font-size);\n  color: var(--secondary-color); }\n\n@media (max-width: 1300px) {\n  .styles_wrapper__fPRz\\+ {\n    justify-content: center; } }\n\n@media (max-width: 768px) {\n  .styles_wrapper__fPRz\\+ {\n    height: 38px; }\n  .styles_info__ZQfKu {\n    font-size: 10px;\n    line-height: 18px; } }\n", "",{"version":3,"sources":["webpack://./src/components/Copyright/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,uCAAuC,EAAA;;AAGzC;EACE,aAAa;EACb,mCAAmC;EACnC,6BAA6B,EAAA;;AAG/B;EACE;IACE,uBAAuB,EAAA,EACxB;;AAGH;EACE;IACE,YAAY,EAAA;EAGd;IACE,eAAe;IACf,iBAAiB,EAAA,EAClB","sourcesContent":[".wrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 46px;\n  background-color: var(--footer-bgcolor);\n}\n\n.info {\n  display: flex;\n  font-size: var(--primary-font-size);\n  color: var(--secondary-color);\n}\n\n@media (max-width: 1300px) {\n  .wrapper {\n    justify-content: center;\n  }\n}\n\n@media (max-width: 768px) {\n  .wrapper {\n    height: 38px;\n  }\n\n  .info {\n    font-size: 10px;\n    line-height: 18px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "styles_wrapper__fPRz+",
	"info": "styles_info__ZQfKu"
};
export default ___CSS_LOADER_EXPORT___;
