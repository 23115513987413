import React from "react";
import Styles from "./styles.module.scss";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import useBlogApi from "../../api/blog/blogApi";

const CardImg = ({ imgSrc, title }) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };
  return (
    <div className={Styles.card_image_container}>
      {!imageError && (
        <img
          className={Styles.card_image}
          src={imgSrc}
          alt={`${title} blog image`}
          onError={handleImageError}
        />
      )}
      {imageError && <div className={Styles.card_image_error} />}
    </div>
  );
};

const Card = ({ title, description, img, id }) => {
  return (
    <div className={Styles.card}>
      <CardImg
        imgSrc={`https://gart.technology${img.data.attributes.url}`}
        title={title}
      />
      <div className={Styles.card_bottom_wrapper}>
        <div className={Styles.card_text_wrapper}>
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            justifySelf: "flex-end",
          }}
        >
          <NavLink to={`/blog/${id}`} className={Styles.more_info}>
            <div className={Styles.button_bg}>
              <span>View Case</span>
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

const BLOG_DATA = [
  {
    imgSrc: "https://fakeimg00/",
    title: "Lorem ipsum",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
  {
    imgSrc: "https://source.unsplash.com/user/c_v_r/1900x800 ",
    title: "Lorem ipsum",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
  {
    imgSrc: "https://source.unsplash.com/user/c_v_r/1900x800 ",
    title: "Lorem ipsum",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
  {
    imgSrc: "https://source.unsplash.com/user/c_v_r/1900x800 ",
    title: "Lorem ipsum",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
  {
    imgSrc: "https://source.unsplash.com/user/c_v_r/1900x800 ",
    title: "Lorem ipsum",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
];

const Blog = () => {
  const { data, status } = useBlogApi();
  const Loader = () => {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          left: "0",
          top: "19vh",
        }}
      >
        <div className={Styles.loader}></div>
      </div>
    );
  };

  return (
    <div className={Styles.wrapper}>
      {status === "loading" && <Loader />}
      {data && data.length !== 0 && <div className={Styles.bg_image} />}
      {data && data.length >= 3 && <div className={Styles.bg_image_2} />}
      {data && data.length !== 0 && <div className={Styles.box_image} />}
      {data && data.length !== 0 && <div className={Styles.box_image_2} />}
      <div className={Styles.title_wrapper}>
        <div className={Styles.shadow_title}>Blog</div>
        <div className={Styles.title_main}>Blog</div>
      </div>

      {status === "success" && data.length !== 0 ? (
        <div className={Styles.blog_wrapper}>
          {data
            .slice(0)
            .reverse()
            .map((blog) => {
              return <Card key={blog.id} id={blog.id} {...blog.attributes} />;
            })}
        </div>
      ) : (
        <>
          <span className={Styles.bg_image_no_posts} />
          <span className={Styles.no_cases}>
            No blog posts have been created yet. Stay tuned for new posts.
          </span>{" "}
        </>
      )}
    </div>
  );
};

export default Blog;
