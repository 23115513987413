import { useState, useEffect } from "react";

function useCareerApi(type, id) {
  const [data, setData] = useState(null);
  const [status, setStatus] = useState("idle");
  let url;
  if (type === "single") {
    url = `https://gart.technology/api/careers/${id}?populate=*`;
  } else {
    url = "https://gart.technology/api/careers?populate=*";
  }
  useEffect(() => {
    if (!url) return;

    const fetchData = async () => {
      setStatus("loading");
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        setData(jsonData.data);
        // console.log("Data fetched successfully:", jsonData);
        setStatus("success");
      } catch (error) {
        setStatus("error");
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [url]);

  return { data, status };
}

export default useCareerApi;
