// Instruments
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Images
import Ingy from "../../theme/assets/images/Ingy.png";
import Zenon from "../../theme/assets/images/Zenon.png";
import Rozman from "../../theme/assets/images/Rozman.png";
import Comment from "../../theme/assets/images/Comment.png";
import Folder from "../../theme/assets/images/Folder.png";
import Data_Analysis from "../../theme/assets/images/Data_Analysis.png";
import Development from "../../theme/assets/images/Development.png";

import StraightLine1 from "../../theme/assets/images/StraightLine.png";
import StraightLine2 from "../../theme/assets/images/StraightLine2.png";
import StraightLine3 from "../../theme/assets/images/StraightLine3.png";
import StraightLine4 from "../../theme/assets/images/StraightLine4.png";
// Styles
import Styles from "./styles.module.scss";

// const feedbacks = [
//   {
//     id: 1,
//     name: "Zenon Radewych",
//     company: "Principal at WZMH Architects, founder of Sparkbird",
//     feedback: [
//       `We have been working with Gart Technology since March 2022 on several smart building/city
//             and AI design related apps and software solutions.
//             We are now collaborating on some very interesting building-city initiatives that we
//             believe will be seen as revolutionary in the industry.`,
//     ],
//     img: Zenon,
//   },
//   {
//     id: 2,
//     name: "Vitaliy Rozman",
//     company: "Owner of AidSupply",
//     feedback: [
//       `I have been working on the Aid Supply since the beginning of COVID-19 pandemic.
// 			When Russia invaded Ukraine in February 2022, I saw the potential of Aid Supply to solve a lot of
// 			humanitarian problems. I’ve carried out some customer interviews with the stakeholders to understand what we need to tweak.
// 			Gart Technology offered their help to add improvements. As a result of our collaboration, the product has definitely improved.
// 			Gart Technology helped to improve our back-end in order to make it faster and implemented new functionality. This helped us to
// 			move faster under the circumstances of war, constant pressure and lack of resources.`,
//     ],
//     img: Rozman,
//   },
// ];

const HowWorksCardsData = [
  {
    id: 1,
    text: "Explain your requirements and the processes you aim to automate to Aki7. You provide an overview of your task and convey your vision for the flow you want to automate.",
    imgPath: Comment,
    alt: "Comment img",
    line: StraightLine1,
  },
  {
    id: 2,
    text: "Aki7 breaks down tasks of varying complexity into manageable plans.",
    imgPath: Folder,
    alt: "Folder img",
    line: StraightLine2,
  },
  {
    id: 3,
    text: "Multiple algorithms of various LLM systems and AI agents work in tandem, leveraging their unique strengths and capabilities to optimize the overall performance and enhance the quality of results.",
    imgPath: Data_Analysis,
    img: "Data Analysis img",
    line: StraightLine3,
  },
  {
    id: 4,
    text: "Aki7 evolves and adapts, it constantly refines its performance through analysis and communication with you.",
    imgPath: Development,
    alt: "Development img",
    line: StraightLine4,
  },
];

function SampleNextArrow(props) {
  const { onClick } = props;

  return (
    <div className={Styles.next_arrow} onClick={onClick}>
      <span />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;

  return (
    <div className={Styles.prev_arrow} onClick={onClick}>
      <span />
    </div>
  );
}

const LinePoint = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={Styles.line_point}
    >
      <circle
        cx="9.99998"
        cy="10"
        r="6.66667"
        fill="url(#paint0_linear_3313_1761)"
      />
      <circle cx="10" cy="10" r="9.5" stroke="url(#paint1_linear_3313_1761)" />
      <defs>
        <linearGradient
          id="paint0_linear_3313_1761"
          x1="22.8012"
          y1="-4.48892"
          x2="6.4881"
          y2="-8.45594"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#32E1F9" stop-opacity="0.5" />
          <stop offset="0.734118" stop-color="#564CEC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3313_1761"
          x1="29.2019"
          y1="-11.7334"
          x2="4.73218"
          y2="-17.684"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#32E1F9" stop-opacity="0.5" />
          <stop offset="0.734118" stop-color="#564CEC" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const HowItWorks = () => {
  const settings = {
    arrows: true,
    dots: false,
    fade: true,
    infinite: true,
    // autoplay: true,
    speed: 1000,
    autoplaySpeed: 7000,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const handleCurrentSlideNum = (id) => {
    if (id < 10) {
      return `0${id}`;
    }
    return id;
  };

  const handleLineClassName = (id) => {
    console.log(`Generated class name: line${id}`);
    return Styles[`line${id}`];
  };

  return (
    <div>
      <section className={Styles.wrapper}>
        <span className={Styles.shadow_title} />
        <h1 className={Styles.title}>How It Works</h1>

        <Slider {...settings}>
          {HowWorksCardsData.map((item) => {
            return (
              <div className={Styles.card_wrapper}>
                <div key={item.id} className={Styles.carousel_card}>
                  <div className={Styles.page_number}>
                    {handleCurrentSlideNum(item.id)}
                  </div>
                  <div className={Styles.feedback}>{item.text}</div>
                  <img
                    className={Styles.card_img}
                    src={item.imgPath}
                    alt={item.alt}
                  />
                  <div>
                    <LinePoint />
                    <div className={Styles.line} />
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </section>
    </div>
  );
};

export default HowItWorks;
