// Core
import { useState, useEffect } from "react";
// Instruments
import emailjs from "emailjs-com";
// Mui
import { Button, Alert, Snackbar } from "@mui/material";
// Styles
import Styles from "./styles.module.scss";
// Components
import Spinner from "../Spinner";

import { InlineWidget } from "react-calendly";

const GetInTouch = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth),
    [loading, setLoading] = useState(false),
    [open, setOpen] = useState(false),
    [answer, setAnswer] = useState({}),
    [docs, setDocs] = useState([]),
    [callIntro, setCallIntro] = useState(true);

  const onFileChange = (event) => {
    setAnswer("");
    const inputFiles = document.getElementById("files").files;
    const files = [...inputFiles];

    if (inputFiles.length > 5) {
      setAnswer({ status: 500, text: "Only 5 files are allowed to upload." });
      setOpen(true);
      return;
    }

    const fileSizeSum = files.reduce((previousValue, currentValue) => {
      return previousValue + currentValue.size;
    }, 0);
    if (fileSizeSum > 20000000) {
      setAnswer({ status: 500, text: "Maximum files size 20mb" });
      setOpen(true);
      return;
    }

    setDocs([...inputFiles]);
  };

  const deleteAllFiles = () => {
    document.getElementById("files").value = null;
    setDocs([]);
  };

  const alertSettings = {
    vertical: "top",
    horizontal: "center",
  };

  const submitForm = (event) => {
    event.preventDefault();
    setLoading(true);
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        event.target,
        process.env.REACT_APP_USER_ID
      )
      .then((result) => {
        if (result.status === 200) {
          setAnswer({
            status: result.status,
            text: "Message Sent, We will get back to you shortly",
          });
          setOpen(true);
          event.target.reset();
          document.getElementById("files").value = null;
          setDocs([]);
          setLoading(false);
        }

        return result.status;
      })
      .catch((error) => {
        if (error.status === 400 || error.status === 0) {
          setAnswer({
            status: error.status,
            text: "An error occurred, Please try again",
          });
          setOpen(true);
          event.target.reset();
          document.getElementById("files").value = null;
          setDocs([]);
          setLoading(false);
        }
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section id={"contact"} className={Styles.container}>
      <div className={Styles.wrapper}>
        <span className={Styles.bg_image} />
        <span className={Styles.shadow_title} />
        <h1 className={Styles.title}>Get in touch</h1>
        <div className={Styles.contact_wrapper}>
          <h2 className={Styles.info_question}>Contact us</h2>
          <h2 className={Styles.info_message}>info@gart.tech</h2>
        </div>
        {/* <form onSubmit={submitForm} className={Styles.form}>
          <div className={Styles.left_column}>
            <div className={Styles.form_item}>
              <p className={Styles.label}>{"Your name"}</p>
              <input
                name={"name"}
                placeholder={"John Johnson"}
                disabled={loading}
                required
                className={Styles.form_field}
              />
            </div>
            <div className={Styles.form_item}>
              <p className={Styles.label}>{"Email"}</p>
              <input
                name={"email"}
                type="email"
                placeholder={"Your email"}
                pattern="[a-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                disabled={loading}
                required
                className={Styles.form_field}
              />
            </div>

            <div className={Styles.form_item}>
              <p className={Styles.label}>{"Company"}</p>
              <input
                name={"company"}
                placeholder={"Name, website, background"}
                disabled={loading}
                required
                className={Styles.form_field}
              />
            </div>

            <div className={Styles.form_item}>
              <p className={Styles.label}>{"Industry"}</p>
              <input
                name={"industry"}
                placeholder={
                  "In which industry will the solution be functioning?"
                }
                disabled={loading}
                required
                className={Styles.form_field}
              />
            </div>
            <div className={Styles.form_item}>
              <p className={Styles.label}>
                {"Do you have the solution already?"}
              </p>
              <input
                name={"solution"}
                placeholder={"No/Yes, but it needs improvement"}
                disabled={loading}
                required
                className={Styles.form_field}
              />
            </div>
          </div>
          <div className={Styles.right_column}>
            <div className={Styles.form_item}>
              <p className={Styles.label}>{"Describe Your request"}</p>
              <textarea
                name={"description"}
                placeholder={"Please, describe what you need help with"}
                disabled={loading}
                rows="10"
                cols="50"
                className={Styles.text_field}
              />
            </div>

            <div className={Styles.files_wrapper}>
              <p className={Styles.label}>{"Attachments"}</p>
              <div className={Styles.content_wrapper}>
                <div className={Styles.files_list}>
                  {docs.map((file, index) => {
                    return (
                      <div key={index}>
                        <p>{file.name}</p>
                      </div>
                    );
                  })}
                </div>
                {!docs.length && (
                  <span className={Styles.input_placeholder}>
                    {"Please, attach one file or all your files at once."}
                  </span>
                )}
                <div className={Styles.buttons}>
                  {!!docs.length && (
                    <button
                      className={Styles.delete_btn}
                      onClick={deleteAllFiles}
                    >
                      {"Delete All"}
                    </button>
                  )}
                  <div>
                    <input
                      type="file"
                      multiple
                      name={"files"}
                      id="files"
                      accept=".png, .pdf, .docx, .doc, .pptx, .ppt, .xlsx, .xls, .pages, .numbers"
                      onChange={onFileChange}
                      className={Styles.file_input}
                      disabled={loading}
                    />

                    <label className={Styles.file_input_label} htmlFor="files">
                      <span>Select a File</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <Button
              type={"submit"}
              disabled={loading}
              variant={"outlined"}
              className={Styles.form_btn}
            >
              <h6>{loading ? <Spinner /> : "Send"}</h6>
            </Button>
          </div>
        </form> */}
        <Snackbar
          anchorOrigin={alertSettings}
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={answer.status === 200 ? "success" : "error"}
            variant={"filled"}
            sx={{ width: "100%" }}
          >
            {answer.text}
          </Alert>
        </Snackbar>

        <div id="schedule_form" className={Styles.meetingsIframe}>
          <div className={Styles.callSomeone}>
            <button
              className={callIntro && Styles.active}
              onClick={() => setCallIntro(true)}
            >
              Ivan K.
            </button>
            <button
              className={!callIntro && Styles.active}
              onClick={() => setCallIntro(false)}
            >
              Liza K.
            </button>
          </div>
          <div className={Styles.inlineWidget}>
            <InlineWidget
              url={
                callIntro
                  ? "https://calendly.com/tech-intro/intro-call-ivan?hide_gdpr_banner=1"
                  : "https://calendly.com/liza-kholod/30min?hide_gdpr_banner=1"
              }
              styles={{
                height: windowWidth < 1024 ? "950px" : "auto",
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetInTouch;
