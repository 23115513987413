import numuw_desktop from "../../theme/assets/images/numuw_desktop.png";
import numuw_phone1 from "../../theme/assets/images/numuw_phone1.png";
import numuw_phone2 from "../../theme/assets/images/numuw_phone2.png";
import numuw_phone3 from "../../theme/assets/images/numuw_phone3.png";
import numuw_phone4 from "../../theme/assets/images/numuw_phone4.png";

import tornado_desktop from "../../theme/assets/images/tornado_desk.png";
import tornado_phone1 from "../../theme/assets/images/tornado_phone1.png";
import tornado_phone2 from "../../theme/assets/images/tornado_phone2.png";
import tornado_phone3 from "../../theme/assets/images/tornado_phone3.png";
import tornado_phone4 from "../../theme/assets/images/tornado_phone4.png";

import bachu_desktop from "../../theme/assets/images/bachu_desktop.png";
import bachu_phone1 from "../../theme/assets/images/bachu_phone1.png";
import bachu_phone2 from "../../theme/assets/images/bachu_phone2.png";
import bachu_phone3 from "../../theme/assets/images/bachu_phone3.png";

import forpost_desktop from "../../theme/assets/images/forpost_desktop.png";
import forpost_phone1 from "../../theme/assets/images/forpost_phone1.png";
import forpost_phone2 from "../../theme/assets/images/forpost_phone2.png";
import forpost_phone3 from "../../theme/assets/images/forpost_phone3.png";
import forpost_phone4 from "../../theme/assets/images/forpost_phone4.png";

import legion_desktop from "../../theme/assets/images/legion_desktop.png";
import legion_phone1 from "../../theme/assets/images/legion_phone1.png";
import legion_phone2 from "../../theme/assets/images/legion_phone2.png";
import legion_phone3 from "../../theme/assets/images/legion_phone3.png";
import legion_phone4 from "../../theme/assets/images/legion_phone4.png";

import saveUA_desktop from "../../theme/assets/images/saveUA_desktop.png";
import saveUA_phone1 from "../../theme/assets/images/saveUA_phone1.png";
import saveUA_phone2 from "../../theme/assets/images/saveUA_phone2.png";
import saveUA_phone3 from "../../theme/assets/images/saveUA_phone3.png";

import case_amazon from "../../theme/assets/icons/case_technologies/case_amazon.svg";
import case_cloudflare from "../../theme/assets/icons/case_technologies/case_cloudflare.svg";
import case_jquery from "../../theme/assets/icons/case_technologies/case_jquery.svg";
import case_kotlin from "../../theme/assets/icons/case_technologies/case_kotlin.svg";
import case_metaMask from "../../theme/assets/icons/case_technologies/case_metaMask.svg";
import case_nodejs from "../../theme/assets/icons/case_technologies/case_nodejs.svg";
import case_PWA from "../../theme/assets/icons/case_technologies/case_PWA.svg";
import case_swift from "../../theme/assets/icons/case_technologies/case_swift.svg";
import case_python from "../../theme/assets/icons/case_technologies/case_python.svg";
import case_tech1 from "../../theme/assets/icons/case_technologies/case_tech1.svg";
import case_tech2 from "../../theme/assets/icons/case_technologies/case_tech2.svg";
import case_tech3 from "../../theme/assets/icons/case_technologies/case_tech3.svg";
import case_tech4 from "../../theme/assets/icons/case_technologies/case_tech4.svg";
import case_tech5 from "../../theme/assets/icons/case_technologies/case_tech5.svg";
import case_tech6 from "../../theme/assets/icons/case_technologies/case_tech6.svg";
import case_tech7 from "../../theme/assets/icons/case_technologies/case_tech7.svg";
import case_js from "../../theme/assets/icons/case_technologies/case_js.png";
import case_postgresql from "../../theme/assets/icons/case_technologies/case_postgresql.png";

export const slides = [
  {
    id: 1,
    title: "Tornado",
    descr: `Our client's request was to create a customized NFT sales tool. It was quite a challenge to move through the 
			discovery, coding, and testing all the way to the working product in a concise time frame. Here's what came out 
      		of our team’s efforts.`,
    dev_descr: `We have built the website using React and created 
      back-end architecture with Django, developed a specialized smart contract and integrated Metamask.`,
    result: `Within 3 weeks, we developed a custom-tailored NFT sales tool with a great user experience. Our web app with mobile and tablet adaptation allowed our client to begin their product sales very quickly.`,
    images: [
      tornado_desktop,
      tornado_phone1,
      tornado_phone2,
      tornado_phone3,
      tornado_phone4,
    ],
    technologies: [
      case_tech6,
      case_tech3,
      case_metaMask,
      case_jquery,
      case_tech7,
    ],
  },
  {
    id: 2,
    title: "Healthcare platform",
    descr: `Numuw is a healthcare platform for performing screening and providing treatment to pediatric patients by 
			licensed therapists. Parents can book sessions, attend video consultations and access medical history within this app.`,
    dev_descr: `While developing,  we use modern and advanced IT technologies that allow us to create user interfaces with the best user experience.`,
    result: `Within 4 months, we created UI and UX, built back-end architecture, and integrated payment providers and multiple user groups with varying payment flows.`,
    images: [
      numuw_desktop,
      numuw_phone1,
      numuw_phone2,
      numuw_phone3,
      numuw_phone4,
    ],
    technologies: [case_js, case_tech3, case_postgresql, case_python],
  },
  {
    id: 3,
    title: "Bachu",
    descr: `A web and mobile apps (IOS and Android) designed for reporting the movements of Russian military vehicles, weapons, troops,
			 or saboteurs on Ukrainian territory. Any Ukrainian user who has spotted the enemy can transmit the exact location directly to 
			 the Security Service of Ukraine.`,
    dev_descr: `We have integrated an ML model developed for recognizing and counting military vehicles and Russian soldiers from each 
			users' photos. We have also built a back-end architecture to centralize the data from apps and place the reports on a map.`,
    result: `Users share their coordinates automatically by sending a report via an app, allowing Ukrainian intelligence to place 
			the enemy's forces on a map, validate reports and prevent attacks.`,
    images: [bachu_desktop, bachu_phone1, bachu_phone2, bachu_phone3],
    technologies: [
      case_tech1,
      case_python,
      case_kotlin,
      case_swift,
      case_PWA,
      case_cloudflare,
    ],
  },
  {
    id: 4,
    title: "Forpost",
    descr: `As the war in Ukraine intensifies, any unusual movement is a cause for alarm. With this app, you can check 
			whether the vehicle is a security threat by photographing the license plate or simply entering the letters and 
			digits you saw.`,
    dev_descr: `We securely synchronized the app’s database with the governmental one to receive updates about vehicles
        	in real-time without disclosing owners’ personal info.`,
    result: `Any verified user can enter license plate’s info there and see brief information 
       		about the vehicle it belongs to (whether the plate matches the car, it is wanted or not, where it was seen, etc.).`,
    images: [
      forpost_desktop,
      forpost_phone2,
      forpost_phone1,
      forpost_phone3,
      forpost_phone4,
    ],
    technologies: [
      case_swift,
      case_tech2,
      case_cloudflare,
      case_amazon,
      case_PWA,
    ],
  },
  {
    id: 5,
    title: "International Legion ",
    descr: `A website and chatbot designed for the non-citizens willing to volunteer for military service in Ukraine. 
      		Our solution assists volunteers to join the Armed Forces of Ukraine with all the logistical help to secure 
			the necessary paperwork.`,
    dev_descr: `The biggest challenge was to build an operation and communication process between our team of IT volunteers 
			(front- and back-end developers, UI/UX designer, and DevOps), the government, and the call center in the first three 
			weeks of the war.`,
    result: `When the Ukrainian government announced the creation of a foreign legion, it was unclear to people how to join it.
			Our website and chatbot solved this problem: we posted all the necessary information about joining, added an application 
			form, and synchronized the process 
      		of submitting the application info to the relevant authorities.`,
    images: [
      legion_desktop,
      legion_phone1,
      legion_phone2,
      legion_phone3,
      legion_phone4,
    ],
    technologies: [case_js, case_tech3, case_postgresql, case_python],
  },
  {
    id: 6,
    title: "SaveUA",
    descr: `The war has posed a host of dire humanitarian problems. SaveUA bot for Telegram and Viber allows you to locate, 
			receive or provide peer-to-peer help in different regions across Ukraine.`,
    dev_descr: `We have built a chatbot for two platforms using Python and integrated a user verification protocol. Aside from 
			that, we made a dashboard that makes it easy to track how many people are ready to help, how many requests have been submitted, 
			in which areas the help is needed, and how quickly the support is provided.`,
    result: `No one can fully vouch for the credibility and thus the safety of individuals seeking or delivering help. 
      		We have created a bot allowing you to exchange contacts quickly with individuals who have been vetted for security.`,
    images: [saveUA_desktop, saveUA_phone1, saveUA_phone2, saveUA_phone3],
    technologies: [
      case_tech3,
      case_nodejs,
      case_tech4,
      case_python,
      case_tech5,
    ],
  },
  {
    id: 7,
    title: "Behavioral customer clustering",
    descr: `Customer segmentation based on demographics or buyer personas describes clients that marketers wish for, not the clients
  		 they actually have.
      	Our task was to help the client determine their customers’ behavioral patterns and thus optimize their marketing efforts.`,
    dev_descr: `For this machine learning project we combined millions of rows of personal and transactional data to develop a model
  		using more than 100 different features to divide existing clients into groups with distinctive behavior.`,
    result: `The hardest part of getting clustering right is creating actionable cluster descriptions.
      	We managed to define 14 distinct clusters that the client company recognized as their customers.`,
    images: [],
    technologies: [],
  },
];

export const cards = [
  {
    id: 1,
    mainTitle: "Human-like AI Agent for Crypto Exchange Platform",
    elements: [
      {
        id: 1,
        text: "Our Client, a leading Crypto Exchange Platform, operates in a dynamic market where currency exchange transactions are extremely time-sensitive. With operations happening across six different time zones, the platform faces the challenge of providing swift and efficient services around the clock. Additionally, as a reputation-based business, the platform often encounters objections from customers which can impact its credibility and trustworthiness in the market.",
      },
      {
        id: 2,
        text: "A goal was to develop a comprehensive solution aimed to be multichannel, enabling seamless communication across various platforms and languages while ensuring zero downtime. Additionally, the system needed to be flexible enough to handle customer objections adeptly, providing contextually appropriate responses and information.",
      },
      {
        id: 3,
        text: "We created an LLM-based agent integrated with different messengers and chat groups. This AI agent performs cold outreach activities, engaging with potential leads and guiding them through the platform's services. Leveraging sophisticated algorithms, the agent efficiently presents the company's offerings and addresses customer objections in real-time, initiating transactions tailored to each client's needs.",
      },
      {
        id: 4,
        text: "The implementation of the AI agent yielded rates in outreach and engagement for the platform across various channels. The client achieved a level of coverage previously unattainable through traditional advertising methods. With one system a company achieved the same results if they hired a minimum of 6 full-time employees. Also, the system enhanced customer service level, including 24/7 online support.",
      },
    ],
  },
  {
    id: 2,
    mainTitle: "Automated Personal Diet Coach ",
    elements: [
      {
        id: 1,
        text: "A health and lifestyle startup in the EU sought to develop a chatbot as a virtual nutrition coach, the challenge was in balancing detailed guidance with motivational messages. Also, the client aimed to foster long-term behavior habit changes in user behavior.",
      },
      {
        id: 2,
        text: "Create a system that offers personalized dietary advice and motivational prompts within professional boundaries to effectively empower users in adopting and maintaining healthy habits.",
      },
      {
        id: 3,
        text: "We developed an advanced chatbot employing sophisticated algorithms to understand user preferences. This allowed us to suggest actionable habits tailored to each user's needs and goals, complemented by proactive reminders for adherence.",
      },
      {
        id: 4,
        text: "The chatbot implementation facilitated scalable personalized diet coaching, enhancing user satisfaction, driving business growth, and serving as a potent lead-generation tool.",
      },
    ],
  },
  {
    id: 3,
    mainTitle: "AI-powered Doctor Assistant",
    elements: [
      {
        id: 1,
        text: "Doctors often struggle with time-consuming documentation after patient consultations.",
      },
      {
        id: 2,
        text: "Our aim was to develop a solution that automates the summarization of patient meetings while maintaining strict data protection standards.",
      },
      {
        id: 3,
        text: "We created a user-friendly system accessible to doctors on any computer. This system records and transcribes patient consultations, providing summarized reports in PDF format following specified templates.",
      },
      {
        id: 4,
        text: "With our solution, doctors can now save up to 40% of their time during initial patient visits. Additionally, to address concerns about data security, the system can be hosted on-premises, ensuring patient confidentiality and compliance with privacy regulations.",
      },
    ],
  },
  {
    id: 4,
    mainTitle: "Healthcare Supply Market Facilitator",
    elements: [
      {
        id: 1,
        text: "A healthcare logistics company aims to automate matchmaking between sellers and buyers in a decentralized market, dealing with diverse data formats and languages, and coordinating communication with decentralized parties.",
      },
      {
        id: 2,
        text: "To develop a system that streamlines data collection, and facilitates communication between buyers and sellers during negotiations.",
      },
      {
        id: 3,
        text: "We created an AI system that aggregates buy-sell offers into a CRM platform, simplifying matchmaking processes, interfaces with parties, gathers relevant information, and facilitates matches between buyers and sellers.",
      },
      {
        id: 4,
        text: "The implemented system significantly reduces manual effort, previously requiring a full-time team of six individuals. Now, only part-time manager supervision is necessary  to oversee the system's operations.",
      },
    ],
  },
  {
    id: 5,
    mainTitle:
      "AI Outstaffing Assistant: Streamlining Lead Responses for IT Company",
    elements: [
      {
        id: 1,
        text: "Our client, an Outstaff IT company, faced the need to respond rapidly and effectively to incoming requests from potential leads across various channels.",
      },
      {
        id: 2,
        text: "Our objective was to develop a system capable of monitoring requests across different channels and messengers, swiftly generating tailored responses to specific inquiries.",
      },
      {
        id: 3,
        text: "We created an automated soulution comprehensively understands the onboarding process for outstaff IT services and utilizes CRM data to track customer engagement across multiple channels. The system promptly generates relevant offers for each request and provides comprehensive information on potential cooperation.",
      },
      {
        id: 4,
        text: "By implementing our solution, a client was able to reduce the outstaff sales team from three full-time employees to one part-time employee, while maintaining the quality and volume of responses.",
      },
    ],
  },
];
