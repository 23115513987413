// Instruments
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
// Styles
import Styles from './styles.module.scss'
// Images
import { useState } from 'react'
import { Button } from '@mui/material'

const Card = ({ item }) => {
	const [details, setDetails] = useState(true)

	return (
		<div className={`${Styles.card_wrapper} ${!details ? Styles.card_wrapper_open : Styles.card_wrapper_closed}`}>
			<div>
				<h3>{item.title}</h3>
				<div className={Styles.more_details} onClick={() => setDetails(!details)}></div>

				<ul className={Styles.description}>
					{item.descr.map((item, index) => {
						return <li key={index}>{item}</li>
					})}
				</ul>
			</div>

			<div className={Styles.button_wrapper}>
				<Button href={'#contact'} variant={'contained'} className={Styles.contact_btn}>
					{'Contact us'}
				</Button>
			</div>
		</div>
	)
}

const phases = [
	{
		id: 1,
		phase: 'Discovery or “Game plan”',
		shortDecr: `Let us help with your idea. We can conduct thorough research and provide you with all the technical options 
        for building the solution you need or what kind of solution will solve your problem best. As a result, you will receive 
        all the necessary information to decide how to proceed with your project.`,
		descr: [
			`It is the initial step that helps clarify the goals, limitations, and the project's overall scope. Moreover, this stage 
      helps to define the MVP and its killer features (unique selling points). It’s an important stage when budget and time are 
      limited, and the detailed tech description (PRD) is absent.`,
		],
		cards: [
			{
				id: 1,
				title: 'In-depth technical problem analysis',
				descr: [
					'-  Development roadmap for an MVP and a full release',
					'-  Terms of reference',
					'-  Work estimates and the development timeline',
					'-  Analysis of the technologies used and potential risks',
				],
			},
			{
				id: 2,
				title: 'In-depth product & technical problem analysis',
				descr: [
					'- Roadmap, terms of reference, work estimates, development timeline, analysis of the technologies used and potential risks',
					'-  Market & competitors research',
					'-  Analysis of risks associated with bringing the product to the market',
					'-  Feature prioritization for different stages of the product',
					'-  Proposals for strengthening the product',
					'-  A pitch deck for the stakeholders',
					'-  Creating wireframes for product visualization',
					'-  Packaging the idea into a finished product',
				],
			},
		],
	},
	{
		id: 2,
		phase: 'Continuous development',
		shortDecr: `Usually, we work with clients like you using the iterative development model. You get each release quickly 
        (even the first one), which saves you a great deal of time and money, minimizes risks, and allows the world to see and 
        use your innovation within a few months. `,
		descr: [
			`At this stage, a defined scope of work is performed, and everything from the 
        project plan for the particular iteration is implemented. 
        Regular communications and reports are set to keep everyone on the same page. `,
		],
		cards: [
			{
				id: 1,
				title: 'Components',
				descr: [
					'-  Determination of development requirements',
					'-  Drawing up the project’s specification',
					'-  Building an applicable team and monitoring their progress',
					'-  Project management using Scrum and Kanban methodologies',
					'-  Creating a product according to SDLC stages (design, coding, quality assurance, deployment)',
					'-  Weekly reports',
				],
			},
		],
	},
	{
		id: 3,
		phase: 'Market release & support',
		shortDecr: `You get a ready-to-use product, primed for presenting it to customers, investors, etc. Moreover, alpha and beta 
        tests can be performed during this phase. After receiving the feedback, the product may be improved through development.`,
		descr: [
			`We gradually transform the initial release by adding new components and all other features required by your business
             and users to make the solution complete and achieve all the business goals for which you needed this product.`,
		],
		cards: [
			{
				id: 1,
				title: 'Product improvement',
				descr: [
					'-  Help with initial performance tests and interpretation of their results',
					'-  Implementation of new features and components',
					'-  Improvement of existing features and components',
					'-  Integration of other solutions into your product',
					'-  Functional and tech documentation provided by our team',
				],
			},
			{
				id: 2,
				title: 'Product support',
				descr: [
					'-  Conducting server maintenance',
					'-  Providing support with the increase in workload (outage)',
					'-  Recommendations for product updates',
					'-  Keeping the product up to date with current technologies and devices',
					'-  Keeping functional and tech documentation up to date',
					'-  A reliable team for future releases',
				],
			},
		],
	},
]

const Phases = () => {
	const { width } = getComputedStyle(document.body)
	const currentWidth = parseFloat(width)

	const autoplay = currentWidth > 1024 ? true : false

	const settings = {
		arrows: false,
		dots: true,
		fade: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		autoplay: autoplay,
		autoplaySpeed: 10000,
		dotsClass: 'vertical-dots',
	}

	return (
		<section className={Styles.container}>
			<div className={Styles.wrapper}>
				<div className={Styles.side_img} />
				<Slider {...settings}>
					{phases.map((item) => {
						return (
							<div key={item.id} className={Styles.carousel_card}>
								<p className={Styles.slide_text}>{item.shortDecr}</p>

								<div className={Styles.mobile_wrapper}>
									<div className={Styles.about_phase}>
										<p>{item.phase}</p>
									</div>
									{item.descr.map((descr, index) => {
										return (
											<div key={index} className={Styles.descr}>
												{descr}
											</div>
										)
									})}
								</div>

								<div className={Styles.cards_wrapper}>
									{item.cards.map((card) => {
										return <Card key={card.id} item={card} />
									})}
								</div>
							</div>
						)
					})}
				</Slider>
				<div className={Styles.slides_line} />
			</div>
		</section>
	)
}

export default Phases
