// Routing
import { Outlet } from "react-router-dom";
// Styles
import Styles from "./styles.module.scss";
// Components
import Copyright from "../Copyright";
import Header from "../Header";
import Footer from "../Footer";
import { useState } from "react";

const Main = (props) => {
  const [sideNavIsOpen, setSideNavIsOpen] = useState(false);
  return (
    <div className={Styles.container}>
      <div>
        <Header
          sideNavIsOpen={sideNavIsOpen}
          setSideNavIsOpen={setSideNavIsOpen}
          headerBorder={props.headerBorder}
        />
        {!sideNavIsOpen && <Outlet />}
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Main;
