// Instruments
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Images
import Ingy from "../../theme/assets/images/Ingy.png";
import Zenon from "../../theme/assets/images/Zenon.png";
import Rozman from "../../theme/assets/images/Rozman.png";
// Styles
import Styles from "./styles.module.scss";

const feedbacks = [
  {
    id: 1,
    name: "Zenon Radewych",
    company: "Principal at WZMH Architects, founder of Sparkbird",
    feedback: [
      `We have been working with Gart Technology since March 2022 on several smart building/city 
            and AI design related apps and software solutions. 
            We are now collaborating on some very interesting building-city initiatives that we 
            believe will be seen as revolutionary in the industry.`,
    ],
    img: Zenon,
  },
  {
    id: 2,
    name: "Vitaliy Rozman",
    company: "Owner of AidSupply",
    feedback: [
      `I have been working on the Aid Supply since the beginning of COVID-19 pandemic.
			When Russia invaded Ukraine in February 2022, I saw the potential of Aid Supply to solve a lot of
			humanitarian problems. I’ve carried out some customer interviews with the stakeholders to understand what we need to tweak.
			Gart Technology offered their help to add improvements. As a result of our collaboration, the product has definitely improved. 
			Gart Technology helped to improve our back-end in order to make it faster and implemented new functionality. This helped us to 
			move faster under the circumstances of war, constant pressure and lack of resources.`,
    ],
    img: Rozman,
  },
];

function SampleNextArrow(props) {
  const { onClick } = props;

  return (
    <div className={Styles.next_arrow} onClick={onClick}>
      <span />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;

  return (
    <div className={Styles.prev_arrow} onClick={onClick}>
      <span />
    </div>
  );
}

const ClientsFeedback = () => {
  const settings = {
    arrows: true,
    dots: false,
    fade: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 7000,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <section className={Styles.wrapper}>
      <Slider {...settings}>
        {feedbacks.map((item) => {
          return (
            <div key={item.id} className={Styles.carousel_card}>
              <div className={Styles.about_author}>
                <img src={item.img} alt={item.name} />
                <div>
                  <p>{item.name}</p>
                  <p>{item.company}</p>
                </div>
                <span className={Styles.quotes} />
              </div>
              <div className={Styles.feedback}>{item.feedback}</div>
            </div>
          );
        })}
      </Slider>
    </section>
  );
};

export default ClientsFeedback;
