import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AboutUs, CoreValues, OurStory } from "../components";

const AboutUsPage = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <OurStory />
      <CoreValues />
      <AboutUs />
    </>
  );
};

export default AboutUsPage;
