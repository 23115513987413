//Core
import { NavLink } from "react-router-dom";
//Styles
import Styles from "./styles.module.scss";

const Card = (props) => {
  const { item } = props;

  return (
    <div className={Styles.card}>
      <h2>{item.title}</h2>
      <div className={Styles.descr_items}>
        {item.descr.map((item, index) => {
          return <p key={index}>{`</${item}>`}</p>;
        })}
      </div>
      <NavLink to="/get-in-touch" className={Styles.contact_button}>
        {"Get in touch"}
      </NavLink>
    </div>
  );
};

const expertise = [
  {
    id: 1,
    title: "<Web>",
    descr: [
      "Design and development",
      "Company intranet and portals",
      "Games",
      "Management automation",
    ],
    class: Styles.card_icon_web,
  },
  {
    id: 2,
    title: "<Apps>",
    descr: ["Native Android", "Native iOS", "Cross-platform"],
    class: Styles.card_icon_apps,
  },
  {
    id: 3,
    title: "<Cybersecurity>",
    descr: [
      "Penetration testing",
      "Smart contract audits",
      "Systems integration",
      "Security optimization",
    ],
    class: Styles.card_icon_defi,
  },
  {
    id: 4,
    title: "<Defi>",
    descr: ["Landing pages", "Games", "Tokens", "Smart contracts"],
    class: Styles.card_icon_ml,
  },
  {
    id: 5,
    title: "<ML>",
    descr: [
      "Image recognition and analysis",
      "Natural language processing (NLP)",
      "Predictive modeling for time series",
    ],
    class: Styles.card_icon_art,
  },
  {
    id: 6,
    title: "<Design>",
    descr: ["UI & UX", "Branding", "Logos", "Visual identity"],
    class: Styles.card_icon_art,
  },
];

const AreasExpertise = () => {
  return (
    <section className={Styles.wrapper}>
      <span className={Styles.shadow_title} />
      <h2 className={Styles.title}>{"Areas of expertise"}</h2>

      <div className={Styles.expertise}>
        {expertise.map((item, index) => {
          return <Card key={item.id} item={item} index={index} />;
        })}
      </div>
    </section>
  );
};

export default AreasExpertise;
