// Styles
import Styles from "./styles.module.scss";

const Copyright = () => {
  return (
    <section className={Styles.wrapper}>
      <p className={Styles.info}>
        {"Copyright © 2023 Gart Technology Co., Ltd. All Rights Reserved"}
      </p>
    </section>
  );
};

export default Copyright;
